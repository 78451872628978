import React from 'react'
import { shape, arrayOf, bool, number } from 'prop-types'
import { unstable_Box as Box } from '@material-ui/core/Box'

import ArticlesAndCaseStudy from './ArticlesAndCaseStudy'
import { articleShape, caseStudyShape } from 'utils/shapes'
import CaseStudies from './CaseStudies'
import Articles from './Articles'

const NewsAndInsights = ({ loading, caseStudies, articles, articleWithImagePosition, full }) => {
  // there are case studies only
  if (!articles.length) {
    return <CaseStudies caseStudies={caseStudies} />
  }

  // there are articles only
  if (!caseStudies.length) {
    return <Articles articles={articles} articleWithImagePosition={articleWithImagePosition} />
  }

  // there are a mix of case studies (one or 2) and articles (1+)
  const topArticles = articles.slice(0, 2)
  const bottomArticles = caseStudies[1] ? articles.slice(2, 4) : articles.slice(2, 7)

  return (
    <Box>
      <ArticlesAndCaseStudy loading={loading} caseStudy={caseStudies[0]} articles={topArticles} />
      {full ? (
        <Box mt={2}>
          {caseStudies[1] ? (
            <ArticlesAndCaseStudy
              loading={loading}
              caseStudy={caseStudies[1]}
              articles={bottomArticles}
              caseStudyOnLeft
            />
          ) : (
            <Articles
              articles={bottomArticles}
              articleWithImagePosition={articleWithImagePosition}
            />
          )}
        </Box>
      ) : null}
    </Box>
  )
}

NewsAndInsights.propTypes = {
  full: bool,
  loading: bool,
  articleWithImagePosition: number,
  articles: arrayOf(shape(articleShape)),
  caseStudies: arrayOf(shape(caseStudyShape))
}

NewsAndInsights.defaultProps = {
  data: []
}

export default NewsAndInsights
