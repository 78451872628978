import { createMiddleware } from 'redux-beacon'
import { LOCATION_CHANGE } from 'connected-react-router'
import GoogleAnalytics, { trackPageView } from '@redux-beacon/google-analytics'

import { GA_TRACKING_ID } from 'utils/constants'

window.ga('create', GA_TRACKING_ID, 'auto')

const eventsMap = {
  [LOCATION_CHANGE]: trackPageView(action => ({
    page: action.payload.location.pathname
  }))
}

export default createMiddleware(eventsMap, GoogleAnalytics())
