import { TBR_LAMBDA_BASE } from 'utils/constants'

const send = (method, api, data = {}) => {
  const url = `${TBR_LAMBDA_BASE}/${api}`

  return fetch(url, {
    method,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
}

export const post = (api, data) => send('POST', api, data)
