import { createActions } from 'redux-actions'

export const {
  snackbar: { openSnackbar, closeSnackbar }
} = createActions({
  '@snackbar/openSnackbar': (message = '', variant = 'success') => ({
    message,
    variant
  }),
  '@snackbar/closeSnackbar': undefined
})
