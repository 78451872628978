import React from 'react'
import { object, string, bool } from 'prop-types'

import StyledInput from 'components/shared/Input'

const Input = ({ input, label, required, disabled }) => (
  <StyledInput required={required} {...input} label={label} disabled={disabled} margin="normal" />
)

Input.propTypes = {
  required: bool,
  input: object.isRequired,
  label: string.isRequired
}

export default Input
