import React from 'react'
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'
import { unstable_Box as Box } from '@material-ui/core/Box'
import Typography from 'components/shared/Typography'
import { shape } from 'prop-types'
import ReactPlayer from 'react-player/youtube'
import PortableText from 'components/shared/PortableText'
import { urlFor } from 'utils/sanity/makeImageUrl'

const styles = ({ breakpoints }) => ({
  whatTBRCanDoSection: {
    [`${breakpoints.down('sm')}`]: {
      marginTop: '60px'
    },
    [`${breakpoints.up('md')}`]: {},
    [`${breakpoints.up('lg')}`]: {
      marginTop: '72px'
    },
    [`${breakpoints.up('xl')}`]: {
      marginTop: '72px'
    }
  },
  videoSection: {
    [`${breakpoints.down('sm')}`]: {
      marginTop: '60px'
    },
    [`${breakpoints.up('md')}`]: {},
    [`${breakpoints.up('lg')}`]: {
      marginTop: '80px'
    },
    [`${breakpoints.up('xl')}`]: {
      marginTop: '80px'
    }
  },
  featuresBox: {
    paddingRight: '35px',
    [`${breakpoints.down('sm')}`]: {
      marginTop: '60px'
    },
    [`${breakpoints.up('md')}`]: {},
    [`${breakpoints.up('lg')}`]: {
      marginTop: '80px'
    },
    [`${breakpoints.up('xl')}`]: {
      marginTop: '80px'
    }
  }
})
const VideoSection = ({ pageContent, classes }) => {
  if (!pageContent) return null

  return (
    <div>
      <Grid container spacing={8}>
        <Grid item xs={12} sm={6} style={{ textAlign: 'initial' }}>
          <Grid item xs={12} sm={6} lg={9} xl={6} className={classes.whatTBRCanDoSection}>
            <Typography
              variant="h1"
              color="textPrimary"
              style={{
                fontWeight: 900,
                textAlign: 'initial',
                marginBottom: '15px'
              }}
            >
              {pageContent.sectionTitle}
            </Typography>
            <PortableText
              variant="h3"
              color="textPrimary"
              text={pageContent.sectionDescription}
              style={{ lineHeight: '1.5', marginTop: '30px' }}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6} className={classes.videoSection} style={{ textAlign: 'center' }}>
          <div className="player-wrapper">
            <ReactPlayer
              url={pageContent.videoUrl}
              width="100%"
              height="100%"
              className="react-player"
            />
          </div>
        </Grid>
        {pageContent.features.map(({ _key, icon, featureTitle, featureDescription }) => (
          <Grid
            key={_key}
            item
            xs={12}
            sm={12}
            md={4}
            lg={4}
            className={classes.featuresBox}
            align="center"
          >
            <Box mb={5} mt={{ xs: 5, sm: 5 }}>
              <img alt={_key} src={urlFor(icon.asset).url()} width="80" />
            </Box>
            <Typography variant="h4" style={{ color: '#264CCA', marginBottom: 10 }}>
              <strong>{featureTitle}</strong>
            </Typography>
            <Typography variant="body1" color="textPrimary">
              {featureDescription}
            </Typography>
          </Grid>
        ))}
      </Grid>
    </div>
  )
}

VideoSection.propTypes = {
  pageContent: shape({}),
  classes: shape({})
}
export default withStyles(styles)(VideoSection)
