const encode = data => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

const postContact = data => {
  // use fetch to post the contact data to self
  // and Netlify will pick it up.
  if (window.location.origin.indexOf('localhost') !== -1) {
    // do nothing
    return new Promise(resolve => resolve({ ok: true }))
  } else {
    const body = encode({
      'form-name': 'contact',
      ...data
    })
    return fetch('/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      body
    })
  }
}

export default postContact
