import React from 'react'
import { Route, Switch } from 'react-router-dom'

import Home from 'pages/Home'
import NewsContainer from 'pages/NewsContainer'
import About from 'pages/About'
import Contact from 'pages/Contact'
import Privacy from 'pages/Privacy'
import TermsAndConditions from 'pages/TermsAndConditions'
import CaseStudy from 'pages/CaseStudy'
import FAQ from 'pages/FAQ'
import Report from 'pages/Report'
import _404 from 'pages/404'

const App = () => (
  <Switch>
    <Route exact path="/" component={Home} />
    <Route exact path="/news/:keyword?" component={NewsContainer} />
    <Route exact path="/about" component={About} />
    <Route exact path="/contact" component={Contact} />
    <Route exact path="/privacy" component={Privacy} />
    <Route exact path="/terms-and-conditions" component={TermsAndConditions} />
    <Route exact path="/case-study/:caseStudyId" component={CaseStudy} />
    <Route exact path="/faqs" component={FAQ} />
    <Route exact path="/report" component={Report} />
    <Route component={_404} />
  </Switch>
)

export default App
