import { string, array, arrayOf, shape, bool, object, number } from 'prop-types'

export const pathShape = {
  link: string.isRequired,
  name: string.isRequired
}

export const growthCentreShape = {
  slug: shape({
    _type: string,
    current: string
  }),
  name: string
}

export const impactShape = {
  barriersReported: number,
  barriersActioned: number,
  insightsShared: number
}

export const organisationShape = {
  name: string,
  url: string,
  logo: string
}

export const qaShape = {
  question: string,
  answer: arrayOf(object),
  useOnHome: bool,
  enabled: bool,
  answeredBy: shape(organisationShape)
}

export const agreementShape = {
  name: string,
  url: string,
  organisations: arrayOf(shape(organisationShape))
}

export const caseStudyShape = {
  heading: string,
  overview: string,
  contentTitle: string,
  content: arrayOf(object),
  background1: string,
  background2: string,
  growthCentres: arrayOf(shape(growthCentreShape)),
  agreement: shape(agreementShape),
  viewCount: number
}

export const articleShape = {
  _id: string,
  heading: string,
  summary: string,
  source: string,
  url: string,
  image: string,
  growthCentres: arrayOf(shape(growthCentreShape))
}

export const contentShape = {
  _id: string,
  heading: string,
  summary: string,
  source: string,
  url: string,
  image: string,
  caseStudyImage: string
}

export const htmlContentShape = {
  content: array
}
