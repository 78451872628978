import React from 'react'
import { object, bool, shape, arrayOf, func } from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import News from 'components/Page/News'
import Loader from 'components/shared/Loader'
import makeClient from 'utils/sanity/makeClient'
import growthCentreToId from 'components/Page/News/growthCentreToId'
import { growthCentreShape } from 'utils/shapes'
import { growthCentreQuery, articleQuery, newsQuery } from 'utils/sanity/queries'
import { updateNewsCache } from 'components/Page/News/actions'

const SEARCH_STOPPED = 0
const ABOUT_TO_SEARCH = 1
const SEARCHING = 2

const PREFIX = 'TBR_'
const numOfArticlesToLoad = [5, 7, 4]
const emptyNews = {
  data: [],
  count: 0
}

export class NewsContainer extends React.Component {
  state = {
    filter: 0,
    keyword: this.props.match.params.keyword || '',
    news: emptyNews,
    loading: false,
    searching: SEARCH_STOPPED,
    error: false
  }

  client = makeClient()

  timer = null

  async componentDidMount() {
    const { keyword } = this.state
    await this.search(keyword)
    document.addEventListener('scroll', this.handleScroll, false)
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount() {
    window.removeEventListener('scroll', this.handleScroll, false)
  }

  // execute the given Sanity query and return result
  runQuery = async query => {
    try {
      return await this.client.fetch(query)
    } catch (error) {
      console.error(error)
      this.setState(prevState => ({
        ...prevState,
        error: true
      }))
    }
    return null
  }

  loadArticles = async (keyword, growthCentre, articleFromIndex, allCaseStudies) => {
    const caseStudies = allCaseStudies.splice(0, 2)
    let articles = []

    // a negative articleFromIndex means no articles left to load
    if (articleFromIndex >= 0) {
      articles = await this.runQuery(
        articleQuery(
          keyword,
          growthCentre,
          `${articleFromIndex}...${articleFromIndex + numOfArticlesToLoad[caseStudies.length]}`
        )
      )
    }

    // fail to load
    if (articles === null) {
      return null
    }

    if (!caseStudies.length && !articles.length) {
      // run out of data
      return {
        allCaseStudies,
        data: [],
        nextArticleIndex: -1,
        articleExhausted: true,
        exhausted: true
      }
    }

    // no more articles to load
    const articleExhausted =
      !articles.length || articles.length < numOfArticlesToLoad[caseStudies.length]
    return {
      allCaseStudies,
      data: [
        {
          caseStudies,
          articles
        }
      ],
      nextArticleIndex: articleExhausted ? -1 : articleFromIndex + articles.length,
      articleExhausted,
      exhausted: articleExhausted && !allCaseStudies.length
    }
  }

  // called every time there are changes in keyword and growthCentre filter
  // search for all the case studies and counts the number of case studies & articles,
  // given keyword and growthCentre
  search = async (keyword, growthCentre) => {
    const { cache, updateNewsCache } = this.props
    const key = `${PREFIX}${keyword}`
    const growthCentreId = growthCentreToId(growthCentre)
    let cachedNews = cache[key] && cache[key][growthCentreId]

    if (cachedNews) {
      // It has been cached before so use the cached version
      return this.setState(prevState => ({
        ...prevState,
        news: cachedNews,
        searching: SEARCH_STOPPED,
        error: false
      }))
    }

    // no cache available so start fetching
    this.setState(prevState => ({
      ...prevState,
      news: emptyNews,
      searching: SEARCHING
    }))

    // load all case studies and the number of case studies and articles
    const initialData = await this.runQuery(newsQuery(keyword, growthCentre))

    if (!initialData) {
      // fail to fetch
      this.setState(prevState => ({
        ...prevState,
        searching: SEARCH_STOPPED
      }))
      return
    }

    // load news
    const news = await this.loadArticles(keyword, growthCentre, 0, initialData.allCaseStudies)

    if (!news) {
      // fail to fetch
      this.setState(prevState => ({
        ...prevState,
        searching: SEARCH_STOPPED
      }))
      return
    }

    // update the display and cache
    this.setState(prevState => ({
      ...prevState,
      searching: SEARCH_STOPPED,
      news: {
        data: news.data,
        count: initialData.count
      }
    }))
    updateNewsCache(key, growthCentreId, {
      ...news,
      count: initialData.count
    })
  }

  // load more articles
  loadMore = async () => {
    const { filter, keyword, loading, searching } = this.state
    const { cache, updateNewsCache, growthCentres } = this.props
    const key = `${PREFIX}${keyword}`
    const growthCentre = growthCentres[filter - 1]
    const growthCentreId = growthCentreToId(growthCentre)
    const cachedNews = cache[key] ? cache[key][growthCentreId] : null

    if (!cachedNews || cachedNews.exhausted || loading || searching) return // no more data to load or data being loaded

    this.setState(prevState => ({
      ...prevState,
      loading: true
    }))

    // load news
    const news = await this.loadArticles(
      keyword,
      growthCentre,
      cachedNews.nextArticleIndex,
      cachedNews.allCaseStudies
    )

    this.setState(prevState => ({
      ...prevState,
      loading: false
    }))

    if (!news) {
      return
    }

    const updatedData = cachedNews.data.concat(news.data)

    updateNewsCache(key, growthCentreId, {
      ...cachedNews,
      ...news,
      data: updatedData
    })

    this.setState(prevState => ({
      ...prevState,
      news: {
        ...prevState.news,
        data: updatedData
      }
    }))
  }

  handleFilterChange = (event, value) => {
    const { keyword } = this.state
    const { growthCentres } = this.props
    this.search(keyword, growthCentres[value - 1])
    this.setState(prevState => ({
      ...prevState,
      filter: value
    }))
  }

  handleInputChange = async ({ target: { value } }) => {
    const { filter } = this.state
    const { growthCentres } = this.props

    this.setState(prevState => ({
      ...prevState,
      keyword: value,
      searching: ABOUT_TO_SEARCH
    }))

    // delay 500ms before start to search
    if (this.timer) {
      clearTimeout(this.timer)
    }
    this.timer = setTimeout(async () => {
      this.search(value, growthCentres[filter - 1])
      this.timer = null
    }, 500)
  }

  handleScroll = () => {
    if (window.innerHeight + window.scrollY >= document.body.offsetHeight - 300) {
      this.loadMore()
    }
  }

  render() {
    const { growthCentres } = this.props
    const {
      filter,
      keyword,
      loading,
      searching,
      error,
      news: { data, count }
    } = this.state

    return (
      <News
        growthCentres={growthCentres}
        searching={searching === SEARCHING}
        loading={loading}
        error={error}
        keyword={keyword}
        filter={filter}
        data={data}
        count={count}
        onInputChange={this.handleInputChange}
        onFilterChange={this.handleFilterChange}
      />
    )
  }
}

NewsContainer.propTypes = {
  match: object.isRequired,
  loading: bool.isRequired,
  growthCentres: arrayOf(shape(growthCentreShape)),
  cache: object.isRequired,
  updateNewsCache: func.isRequired
}

const mapStateToProps = ({ news: { cache } }) => ({ cache })

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      updateNewsCache
    },
    dispatch
  )
export default Loader({ growthCentres: growthCentreQuery })(
  connect(mapStateToProps, mapDispatchToProps)(NewsContainer)
)
