import React from 'react'
import { object } from 'prop-types'
import InputBase from '@material-ui/core/InputBase'
import { withStyles } from '@material-ui/core/styles'
import FormControl from '@material-ui/core/FormControl'

/* istanbul ignore next */
const styles = theme => ({
  input: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.dark,
    fontSize: 16,
    fontWeight: 500,
    padding: '10px 12px',
    borderRadius: '0 100px 100px 0'
  },
  image: {
    position: 'absolute',
    right: '-15px',
    bottom: '0px',
    paddingLeft: '5px',
    width: '46px'
  }
})

export const Input = ({ classes, ...props }) => (
  <FormControl fullWidth style={{ position: 'relative' }}>
    <img alt="search" src="/images/icons/search.svg" className={classes.image} />
    <InputBase
      classes={{
        input: classes.input
      }}
      {...props}
    />
  </FormControl>
)

Input.propTypes = {
  classes: object.isRequired
}

export default withStyles(styles)(Input)
