import React from 'react'
import { string } from 'prop-types'
import { unstable_Box as Box } from '@material-ui/core/Box'
import Typography from 'components/shared/Typography'

const GrowthCentre = ({ name }) => (
  <Typography
    variant="h5"
    color="textSecondary"
    style={{
      border: 'solid 2px white',
      borderRadius: '21px'
    }}
  >
    <Box px={2} py={1}>
      {name}
    </Box>
  </Typography>
)

GrowthCentre.propTypes = {
  name: string
}

export default GrowthCentre
