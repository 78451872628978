import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import { MuiThemeProvider } from '@material-ui/core/styles'
import { JssProvider, createGenerateClassName } from 'react-jss'
import CssBaseline from '@material-ui/core/CssBaseline'

import App from 'App'
import theme from 'theme'
// import * as serviceWorker from 'serviceWorker'
import store, { history } from 'store'

import './index.scss'

const generateClassName = createGenerateClassName()

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <MuiThemeProvider theme={theme}>
        <div style={{ display: 'flex', minHeight: '100vh' }}>
          <CssBaseline />
          <JssProvider generateClassName={generateClassName}>
            <App />
          </JssProvider>
        </div>
      </MuiThemeProvider>
    </ConnectedRouter>
  </Provider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// serviceWorker.register()
