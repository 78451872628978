import React from 'react'
import { object } from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { Link } from 'react-router-dom'
import Grid from '@material-ui/core/Grid'
import Typography from 'components/shared/Typography'
import { unstable_Box as Box } from '@material-ui/core/Box'

/* istanbul ignore next */
const styles = ({ palette: { primary } }) => ({
  footer: {
    backgroundColor: primary.contrastText
  },
  link: {
    textDecoration: 'none'
  }
})

const items = [
  {
    name: 'FAQs',
    link: '/faqs'
  },
  {
    name: 'Contact Us',
    link: '/contact'
  },
  {
    name: 'Privacy Policy',
    link: '/privacy'
  },
  {
    name: 'Terms & Conditions',
    link: '/terms-and-conditions'
  }
]

export const Footer = ({ classes }) => (
  <Box
    py={{ xs: 3, md: 5, lg: 5 }}
    px={{ xs: 2, md: 4, lg: 19 }}
    className={classes.footer}
    width="100%"
  >
    <Grid container direction="column">
      <Grid container justify="flex-end">
        {items.map(({ name, link }) => (
          <Grid item key={name}>
            <Link className={classes.link} to={link}>
              <Typography variant="body1" color="secondary">
                <Box ml={{ xs: 2, sm: 3, md: 5, lg: 7 }} mb={{ xs: 1, sm: 0 }}>
                  {name}
                </Box>
              </Typography>
            </Link>
          </Grid>
        ))}
      </Grid>
      <Grid item>
        <Box pt={2} mt={2} style={{ borderTop: 'solid 1px white' }}>
          <Typography variant="body2" color="textSecondary" inline>
            Disclaimer:{' '}
          </Typography>
          <Typography variant="body2" color="secondary" inline>
            The information contained in the Trade Barriers Register and (TBR) was prepared by Food
            Innovation Australia Ltd (ABN 50 164 124 609) and the Export Council of Australia (ABN
            98 004 378 287). The TBR case studies, news and data have been compiled from information
            and material supplied by third party sources as well as publicly available which may (in
            part) be inaccurate or incomplete and which has not been, independently verified by
            FIAL, nor ECA. FIAL and ECA makes no representation, warranty or guarantee as to the
            accuracy, reliability, currency or completeness of the information provided in the TBR.
            Use or reliance upon this information is subject to and conditional on the user not
            holding FIAL, nor ECA, liable in any way for any impact or consequence arising from any
            of the above factors or the user&apos;s reliance on the contents of the TBR
          </Typography>
        </Box>
      </Grid>
    </Grid>
  </Box>
)

Footer.propTypes = {
  classes: object.isRequired
}

export default withStyles(styles)(Footer)
