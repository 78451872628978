import React from 'react'
import { shape, bool } from 'prop-types'
import Grid from '@material-ui/core/Grid'
import Typography from 'components/shared/Typography'
import Button from '@material-ui/core/Button'
import { unstable_Box as Box } from '@material-ui/core/Box'
import Hidden from '@material-ui/core/Hidden'
import Truncate from 'react-truncate'
import { Link } from 'react-router-dom'

import Agreement from 'components/shared/Agreement'
import { caseStudyShape } from 'utils/shapes'

const CaseStudy = ({ readMore, caseStudy }) => (
  <Grid container item justify="space-between">
    <Grid container item direction="column" xs={12} lg={6} spacing={24}>
      <Grid item>
        <Typography variant="h2" color="textPrimary">
          Case Study Summary
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="body1">
          <Truncate lines={8}>{caseStudy.overview}</Truncate>
        </Typography>
      </Grid>
      {readMore ? (
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            size="large"
            component={Link}
            to={`/case-study/${caseStudy._id}`}
          >
            <Typography variant="h4" color="textSecondary">
              <Box py={1}>Read More</Box>
            </Typography>
          </Button>
        </Grid>
      ) : null}
    </Grid>
    <Hidden mdDown>
      <Grid container direction="column" item lg={5}>
        <Grid item>
          <Agreement full agreement={caseStudy.agreement} />
        </Grid>
      </Grid>
    </Hidden>
  </Grid>
)

CaseStudy.propTypes = {
  readMore: bool,
  caseStudy: shape(caseStudyShape)
}

export default CaseStudy
