import React from 'react'
import { shape, bool } from 'prop-types'

import ContentCell from 'components/shared/ContentCell'
import { articleShape } from 'utils/shapes'

const ArticleWithImage = ({ loading, article }) => (
  <ContentCell loading={loading} content={article} showImage />
)

ArticleWithImage.propTypes = {
  loading: bool,
  article: shape(articleShape)
}

export default ArticleWithImage
