import React from 'react'
import { object } from 'prop-types'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'
import Typography from 'components/shared/Typography'
import { withRouter } from 'react-router-dom'
import { unstable_Box as Box } from '@material-ui/core/Box'

import Page from 'components/Page/Page'

/* istanbul ignore next */
const styles = ({ breakpoints }) => ({
  container: {
    flex: 1,
    padding: '200px 0 100px 0',
    [breakpoints.down('xs')]: {
      padding: '150px 0 50px 0'
    },
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundImage: 'url(/images/backgrounds/404.jpg)'
  },
  heading: {
    fontSize: 300,
    lineHeight: '250px',
    [breakpoints.down('xs')]: {
      fontSize: 100,
      lineHeight: '75px'
    }
  }
})

export const Error = ({ classes, history }) => (
  <Page transparentNav title="Error">
    <Grid
      container
      direction="column"
      alignItems="center"
      className={classes.container}
      spacing={24}
    >
      <Grid item>
        <Typography variant="h1" className={classes.heading} color="secondary">
          500
        </Typography>
      </Grid>
      <Grid item style={{ marginBottom: '40px' }}>
        <Typography variant="h3" color="secondary">
          There have been errors! Please try again later
        </Typography>
      </Grid>
      <Grid item>
        <Button variant="contained" color="secondary" size="large" onClick={() => history.go(0)}>
          <Typography variant="h3" color="primary">
            <Box p={2}>Refresh</Box>
          </Typography>
        </Button>
      </Grid>
    </Grid>
  </Page>
)

Error.propTypes = {
  history: object.isRequired,
  classes: object.isRequired
}

export default withStyles(styles)(withRouter(Error))
