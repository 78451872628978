import React from 'react'
import { arrayOf, string } from 'prop-types'
import Grid from '@material-ui/core/Grid'
import { unstable_Box as Box } from '@material-ui/core/Box'
import Typography from 'components/shared/Typography'

import GrowthCentre from 'components/shared/GrowthCentre'

const Header = ({ growthCentres, heading, background }) => (
  <Grid
    container
    direction="column"
    style={{
      position: 'relative',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center center',
      backgroundSize: 'cover',
      backgroundImage: `url(${background})`
    }}
  >
    <Box
      pl={{ xs: 3, sm: 6, md: 8, lg: 19 }}
      pt={{ xs: 18, md: 25, lg: 28, xl: 28 }}
      pb={{ xs: 10, md: 30, lg: 25, xl: 45 }}
    >
      <Grid container>
        {growthCentres.map(growthCentre => (
          <Box key={growthCentre} mr={2}>
            <GrowthCentre name={growthCentre} />
          </Box>
        ))}
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h1" color="textSecondary">
          <Box my={{ xs: 2, sm: 4, xl: 6 }}>{heading}</Box>
        </Typography>
      </Grid>
    </Box>
  </Grid>
)

Header.propTypes = {
  heading: string,
  background: string,
  growthCentres: arrayOf(string)
}

Header.defaultProps = {
  growthCentres: []
}

export default Header
