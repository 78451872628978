import React from 'react'
import { object, shape, bool } from 'prop-types'
import { withRouter } from 'react-router'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Typography from 'components/shared/Typography'
import { unstable_Box as Box } from '@material-ui/core/Box'
import Truncate from 'react-truncate'

import Clickable from 'components/shared/Clickable'
import truncateURL from './truncateURL'
import isObjectNotNull from 'utils/isObjectNotNull'
import { contentShape } from 'utils/shapes'

const styles = {
  container: {
    height: '100%',
    backgroundColor: '#fff',
    boxShadow: '0 1px 3px 0 rgba(0,0,0,0.14)'
  },
  image: {
    width: '100%'
  }
}

export const ContentCell = ({ history, content, showImage, classes }) => {
  if (!isObjectNotNull(content)) return null

  return (
    <Grid container direction="column" className={classes.container}>
      {showImage && (
        <Grid
          container
          style={{
            height: '300px',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundImage: `url(${content.image || content.background1})`
          }}
        />
      )}
      <Box px={2} py={3} style={{ flex: 1 }}>
        <Grid container direction="column" style={{ height: '100%' }}>
          {!showImage && (
            <Grid item>
              <Typography variant="h3" color="textPrimary">
                <Box mb={2}>{content.source || 'Trade Barriers Case Study'}</Box>
              </Typography>
            </Grid>
          )}
          <Grid item>
            <Typography variant="h5">
              <Box mb={2}>{content.heading}</Box>
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body1">
              <Box mb={1.5}>
                <Truncate lines={3}>{content.summary || content.overview}</Truncate>
              </Box>
            </Typography>
          </Grid>
          {content.url ? (
            <Grid item>
              <Typography variant="subtitle1">
                <Box mb={2}>{truncateURL(content.url)}</Box>
              </Typography>
            </Grid>
          ) : null}
          <Grid item style={{ marginTop: 'auto' }}>
            <Clickable
              onClick={() => {
                if (content.url) {
                  // an article
                  window.open(content.url, '_blank')
                } else {
                  // a case study
                  history.push(`/case-study/${content._id}`)
                }
              }}
            >
              <Typography variant="body1" align="right">
                More Info <img alt="more info" src="/images/icons/more-black.svg" />
              </Typography>
            </Clickable>
          </Grid>
        </Grid>
      </Box>
    </Grid>
  )
}

ContentCell.propTypes = {
  showImage: bool,
  showActions: bool,
  loading: bool,
  classes: object.isRequired,
  content: shape(contentShape),
  history: object.isRequired
}

export default withRouter(withStyles(styles)(ContentCell))
