import React from 'react'
import { arrayOf, shape, string, number } from 'prop-types'
import Grid from '@material-ui/core/Grid'
import Typography from 'components/shared/Typography'
import Button from '@material-ui/core/Button'
import { Link } from 'react-router-dom'
import { unstable_Box as Box } from '@material-ui/core/Box'

import { pathShape } from 'utils/shapes'

const Menu = ({ paths, button, currentPath, opacity, shrink }) => (
  <Grid container item alignItems="center">
    {paths.map(({ link, name }) => (
      <Grid item key={link}>
        <Link to={link}>
          <Typography variant="h4" color={`${opacity < 0.5 ? 'textSecondary' : 'textPrimary'}`}>
            <Box
              mx={1.5}
              style={{
                borderBottom:
                  link === currentPath ? `solid 1px ${opacity < 0.5 ? '#fff' : '#444'}` : 'none'
              }}
            >
              {name}
            </Box>
          </Typography>
        </Link>
      </Grid>
    ))}
    <Grid item>
      <Box ml={2}>
        <Button
          variant={`${opacity < 0.5 ? 'contained' : shrink < 0.5 ? 'contained' : 'contained'}`}
          component={Link}
          to={button.link}
        >
          <Typography variant="h4" color={'textSecondary'}>
            <Box p={1}>{button.name}</Box>
          </Typography>
        </Button>
      </Box>
    </Grid>
  </Grid>
)

Menu.propTypes = {
  shrink: number.isRequired,
  opacity: number.isRequired,
  currentPath: string.isRequired,
  paths: arrayOf(shape(pathShape)).isRequired,
  button: shape(pathShape).isRequired
}

export default Menu
