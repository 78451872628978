import imageUrlBuilder from '@sanity/image-url'

import { TBR_API_PROJECT_ID, TBR_API_DATASET, TBR_API_USE_CDN } from 'utils/constants'

// Get a pre-configured url-builder from your sanity client
const builder = imageUrlBuilder({
  projectId: TBR_API_PROJECT_ID,
  dataset: TBR_API_DATASET,
  useCdn: TBR_API_USE_CDN
})

export const urlFor = source => {
  return builder.image(source)
}
