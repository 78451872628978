import { handleActions } from 'redux-actions'

import { updateData } from './actions'

export const INITIAL_STATE = {}

export default handleActions(
  {
    [updateData]: (state, { payload: { data } }) => ({
      ...state,
      ...data
    })
  },
  INITIAL_STATE
)
