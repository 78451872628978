import { createMuiTheme } from '@material-ui/core/styles'

let theme = createMuiTheme({
  spacing: 8,
  /* breakpoints: {
    values: {
      xs: 0,
      sm: 576,
      md: 768,
      lg: 992,
      xl: 1200
    }
  }, */
  palette: {
    primary: {
      light: '#f2f2f2',
      main: '#1c3069',
      dark: '#212121',
      contrastText: '#0e1d28'
    },
    secondary: {
      light: '#f2f2f2',
      main: '#fff',
      dark: '#f2f2f2',
      contrastText: '#0e1d28'
    }
  },
  typography: {
    useNextVariants: true,
    fontFamily: '"Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif'
  }
})

theme = {
  ...theme,
  overrides: {
    MuiButton: {
      root: {
        borderRadius: 0,
        textTransform: 'none'
      },
      contained: {
        border: 'none',
        backgroundColor: '#264CCA',
        textTransform: 'none'
      },
      containedSecondary: {
        border: 'none',
        backgroundColor: '#264CCA',
        textTransform: 'none'
      },
      outlined: {
        border: '1px solid #0E1D28',
        textTransform: 'none'
      }
    },
    MuiTab: {
      root: {
        textTransform: 'none'
      },
      selected: {
        color: theme.palette.primary.dark
      },
      textColorPrimary: {
        color: '#878787'
      }
    },
    MuiTabs: {
      root: {
        boxShadow: '0 1px 2px 0 rgba(221,221,221,0.5)'
      },
      indicator: {
        height: 5
      }
    },
    MuiTypography: {
      colorPrimary: {
        color: '#212121'
      },
      colorTextPrimary: {
        color: '#212121',
        fontWeight: 500
      },
      colorSecondary: {
        color: '#fff'
      },
      colorTextSecondary: {
        color: '#fff',
        fontWeight: 500
      },
      h1: {
        color: '#0e1d28',
        [theme.breakpoints.down('sm')]: {
          fontSize: 20
        },
        [theme.breakpoints.up('md')]: {
          fontSize: 40
        },
        [theme.breakpoints.up('lg')]: {
          fontSize: 50
        }
      },
      h2: {
        color: '#0e1d28',
        [theme.breakpoints.down('sm')]: {
          fontSize: 18
        },
        [theme.breakpoints.up('md')]: {
          fontSize: 35
        },
        [theme.breakpoints.up('lg')]: {
          fontSize: 40
        }
      },
      h3: {
        color: '#212121',
        [theme.breakpoints.down('sm')]: {
          fontSize: 14
        },
        [theme.breakpoints.up('md')]: {
          fontSize: 16
        },
        [theme.breakpoints.up('lg')]: {
          fontSize: 18
        }
      },
      h4: {
        color: '#212121',
        [theme.breakpoints.down('sm')]: {
          fontSize: 12
        },
        [theme.breakpoints.up('md')]: {
          fontSize: 14
        },
        [theme.breakpoints.up('lg')]: {
          fontSize: 16
        }
      },
      h5: {
        color: '#4885ed',
        [theme.breakpoints.down('sm')]: {
          fontSize: 12
        },
        [theme.breakpoints.up('md')]: {
          fontSize: 14
        },
        [theme.breakpoints.up('lg')]: {
          fontSize: 16
        }
      },
      h6: {
        color: '#878787',
        [theme.breakpoints.down('sm')]: {
          fontSize: 12
        },
        [theme.breakpoints.up('md')]: {
          fontSize: 14
        },
        [theme.breakpoints.up('lg')]: {
          fontSize: 16
        }
      },
      subtitle1: {
        fontSize: 13,
        color: '#878787'
      },
      body1: {
        color: '#0e1d28',
        [theme.breakpoints.down('sm')]: {
          fontSize: 12
        },
        [theme.breakpoints.up('md')]: {
          fontSize: 16
        }
      },
      body2: {
        color: '#0e1d28',
        fontSize: 10
      }
    }
  }
}

export default theme
