import React from 'react'
import { number, string, func, bool } from 'prop-types'
import Grid from '@material-ui/core/Grid'
import Typography from 'components/shared/Typography'
import Button from '@material-ui/core/Button'
import { unstable_Box as Box } from '@material-ui/core/Box'

const Result = ({ searching, loadingMore, error, filter, keyword, count = 0, searchAll }) => {
  if (searching) {
    return null
  }

  if (error && !loadingMore) {
    return (
      <Typography variant="h4" color="textPrimary">
        There have been errors. Please try again later!
      </Typography>
    )
  }

  if (!keyword) {
    return null
  }

  if (!count) {
    return (
      <Grid container direction="column" spacing={24}>
        <Grid item>
          <Typography variant="h4" color="textPrimary">
            No Results Found
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="body1" color="textPrimary">
            To get more results, try adjusting your search by changing the keywords
            {filter ? ' or remove industry filter and search all new and insights' : ''}
          </Typography>
        </Grid>
        {filter ? (
          <Grid item>
            <Button variant="outlined" size="large" onClick={searchAll}>
              <Typography variant="h4" color="textPrimary">
                <Box py={1}>Search all</Box>
              </Typography>
            </Button>
          </Grid>
        ) : null}
      </Grid>
    )
  }

  return (
    <Typography variant="h4" color="textPrimary">
      {count} results found for <q>{keyword}</q>
    </Typography>
  )
}

Result.propTypes = {
  error: bool,
  searching: bool,
  loadingMore: bool,
  filter: bool,
  count: number,
  keyword: string.isRequired,
  searchAll: func.isRequired
}

export default Result
