import React from 'react'
import { bool, arrayOf, shape } from 'prop-types'
import { unstable_Box as Box } from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Typography from 'components/shared/Typography'

import ContentCell from 'components/shared/ContentCell'
import QA from 'components/shared/QA'
import Loader from 'components/shared/Loader'
import { otherInterestQuery } from 'utils/sanity/queries'
import { articleShape, qaShape } from 'utils/shapes'

export const OtherInterests = ({
  darkBackground,
  articleOnly,
  otherInterests: { articles, faq }
}) => (
  <Grid
    container
    item
    direction="column"
    style={{ backgroundColor: darkBackground ? '#F2F2F2' : '#fff' }}
  >
    <Grid item>
      <Typography variant="h1" color="textPrimary">
        <Box mb={5}>You may be also be interested in</Box>
      </Typography>
    </Grid>
    <Grid container>
      <Grid item xs={12} lg={6}>
        <Box pr={{ xs: 0, md: 4 }} pb={{ xs: 2, md: 0 }} height="100%">
          <ContentCell content={articles[0]} showImage />
        </Box>
      </Grid>
      <Grid item xs={12} lg={6}>
        {articleOnly ? <ContentCell content={articles[1]} showImage /> : <QA qa={faq} readMore />}
      </Grid>
    </Grid>
  </Grid>
)

OtherInterests.propTypes = {
  darkBackground: bool,
  articleOnly: bool,
  otherInterests: shape({
    articles: arrayOf(shape(articleShape)),
    faq: shape(qaShape)
  })
}

OtherInterests.defaultProps = {
  otherInterests: {
    articles: []
  }
}

export default Loader({ otherInterests: otherInterestQuery }, false)(OtherInterests)
