import React from 'react'
import { bool, string, func } from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Snackbar from '@material-ui/core/Snackbar'

import SnackbarContent from './SnackbarContent'
import { closeSnackbar } from './actions'

const CustomSnackbar = ({ open, variant, message, closeSnackbar }) => (
  <Snackbar
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'left'
    }}
    open={open}
    autoHideDuration={6000}
    onClose={closeSnackbar}
  >
    <SnackbarContent onClose={closeSnackbar} variant={variant} message={message} />
  </Snackbar>
)

CustomSnackbar.propTypes = {
  open: bool.isRequired,
  variant: string.isRequired,
  message: string.isRequired,
  closeSnackbar: func.isRequired
}

const mapStateToProps = ({ snackbar: { open, variant, message } }) => ({ open, variant, message })
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      closeSnackbar
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(CustomSnackbar)
