import { handleActions } from 'redux-actions'

import { updateNewsCache } from './actions'

export const INITIAL_STATE = {
  cache: {}
}

export default handleActions(
  {
    [updateNewsCache]: (state, { payload: { keyword, growthCentre, news } }) => ({
      ...state,
      cache: {
        ...state.cache,
        [keyword]: {
          ...state.cache[keyword],
          [growthCentre]: news
        }
      }
    })
  },
  INITIAL_STATE
)
