import React from 'react'
import { shape, arrayOf, bool } from 'prop-types'
import Grid from '@material-ui/core/Grid'
import { unstable_Box as Box } from '@material-ui/core/Box'

import ContentCell from 'components/shared/ContentCell'
import { articleShape } from 'utils/shapes'

const DoubleArticles = ({ loading, articles }) => (
  <Grid container direction="column" style={{ height: '100%' }}>
    <Grid item style={{ flex: 1 }}>
      <Box height="100%">
        <ContentCell loading={loading} content={articles[0]} />
      </Box>
    </Grid>
    <Grid item style={{ flex: 1 }}>
      <Box pt={2} height="100%">
        <ContentCell loading={loading} content={articles[1]} />
      </Box>
    </Grid>
  </Grid>
)

DoubleArticles.propTypes = {
  loading: bool,
  articles: arrayOf(shape(articleShape))
}

export default DoubleArticles
