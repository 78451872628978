import React from 'react'
import { shape, arrayOf } from 'prop-types'
import Grid from '@material-ui/core/Grid'
import { unstable_Box as Box } from '@material-ui/core/Box'
import Typography from 'components/shared/Typography'
import Slider from 'react-slick'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import { organisationShape } from 'utils/shapes'

const CAROUSEL_SETTINGS = {
  className: 'slider variable-width',
  dots: false,
  arrows: false,
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  speed: 6000,
  autoplaySpeed: 0,
  cssEase: 'linear',
  variableWidth: true
}

const Organisations = ({ organisations }) => {
  if (!organisations.length) return null

  return (
    <Grid container direction="column">
      <Grid item>
        <Typography variant="h3" color="textPrimary">
          <Box mb={4}>Related Organisations</Box>
        </Typography>
      </Grid>
      <Grid item>
        <Slider {...CAROUSEL_SETTINGS}>
          {organisations.map(({ name, logo, url }) => (
            <a
              key={name}
              href={url}
              target="_blank"
              rel="noopener noreferrer"
              className="text-center"
            >
              <Box mx={2}>
                <img alt={name} src={`${logo}?h=50`} style={{ height: '50px', width: 'auto' }} />
              </Box>
            </a>
          ))}
        </Slider>
      </Grid>
    </Grid>
  )
}

Organisations.propTypes = {
  organisations: arrayOf(shape(organisationShape))
}

Organisations.defaultProps = {
  organisations: []
}

export default Organisations
