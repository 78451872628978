import PropTypes from 'prop-types'

import { SITE_TITLE } from 'utils/constants'

const Title = ({ title }) => {
  document.title = title ? `${SITE_TITLE} - ${title}` : `${SITE_TITLE}`
  return null
}

Title.propTypes = {
  title: PropTypes.string
}

export default Title
