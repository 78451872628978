import React from 'react'
import { array, string } from 'prop-types'
import withWidth from '@material-ui/core/withWidth'

import Typography from 'components/shared/Typography'
import toHTML from './toHTML'

const PortableText = ({ text, width, ...rest }) => (
  <Typography variant="body1" dangerouslySetInnerHTML={toHTML(width)(text)} {...rest} />
)

PortableText.propTypes = {
  text: array.isRequired,
  width: string.isRequired
}

export default withWidth()(PortableText)
