import React from 'react'
import { arrayOf, shape, func } from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import Grid from '@material-ui/core/Grid'
import { unstable_Box as Box } from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Typography from 'components/shared/Typography'

import Page from 'components/Page/Page'
import Loader from 'components/shared/Loader'
import Header from 'components/Page/FAQ/Header'
import QA from 'components/shared/QA'
import Input from 'components/shared/Input'
import OtherInterests from 'components/shared/OtherInterests'
import * as lambdaClient from 'utils/lambdaClient'
import { openSnackbar } from 'components/shared/Snackbar/actions'
import { faqsQuery } from 'utils/sanity/queries'
import { qaShape } from 'utils/shapes'

const ITEMS_PER_LOAD = 3

export class FAQ extends React.Component {
  state = {
    lastItem: ITEMS_PER_LOAD,
    question: '',
    sendingQuestion: false
  }

  viewMore = () => {
    this.setState({
      lastItem: Math.min(this.state.lastItem + ITEMS_PER_LOAD, this.props.faqs.length)
    })
  }

  handleInput = evt =>
    this.setState({
      ...this.state,
      question: evt.target.value
    })

  sendQuestion = async () => {
    const { question } = this.state
    const { openSnackbar } = this.props
    try {
      this.setState(prevState => ({
        ...prevState,
        sendingQuestion: true
      }))
      await lambdaClient.post('newQuestion', { question })
      this.setState(prevState => ({
        ...prevState,
        question: ''
      }))
      openSnackbar('Your question sent!')
    } catch (error) {
      console.error(error)
      openSnackbar('There have been errors. Try again later!', 'error')
    }
    this.setState(prevState => ({
      ...prevState,
      sendingQuestion: false
    }))
  }

  render() {
    const { faqs } = this.props
    const { lastItem, question, sendingQuestion } = this.state
    const sorterFaqs = faqs && faqs.sort((a, b) => a.sortOrder - b.sortOrder)

    return (
      <Page title="FAQs">
        <Grid container direction="column">
          <Grid item>
            <Header />
          </Grid>
          <Box px={{ xs: 2, sm: 4, md: 8, lg: 19 }} py={{ xs: 2, sm: 5 }}>
            <Grid container direction="column">
              {sorterFaqs.slice(0, lastItem).map(faq => (
                <Grid item key={faq.question}>
                  <Box py={{ xs: 1, sm: 3 }}>
                    <QA qa={faq} showLogo />
                  </Box>
                </Grid>
              ))}
            </Grid>
            {lastItem < faqs.length ? (
              <Grid container item justify="flex-end">
                <Box mt={2}>
                  <Button variant="outlined" onClick={this.viewMore}>
                    See more Questions
                  </Button>
                </Box>
              </Grid>
            ) : null}
          </Box>
          <Grid container direction="column" style={{ backgroundColor: '#F2F2F2' }}>
            <Box px={{ xs: 2, sm: 4, md: 8, lg: 19 }} py={{ xs: 5, md: 10 }}>
              <Grid item>
                <Typography variant="h1" color="textPrimary">
                  <Box mb={5}>Have a question?</Box>
                </Typography>
              </Grid>
              <Grid container>
                <Grid item xs={9} sm={10}>
                  <Input
                    placeholder="I want to ask about..."
                    value={question}
                    onChange={this.handleInput}
                    disabled={sendingQuestion}
                  />
                </Grid>
                <Grid container item xs={3} sm={2} justify="flex-end">
                  <Button
                    variant="outlined"
                    onClick={this.sendQuestion}
                    disabled={!question.length || sendingQuestion}
                  >
                    Send!
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item>
            <Box px={{ xs: 2, sm: 4, md: 8, lg: 19 }} py={{ xs: 3, sm: 6 }}>
              <OtherInterests articleOnly />
            </Box>
          </Grid>
        </Grid>
      </Page>
    )
  }
}

FAQ.propTypes = {
  faqs: arrayOf(shape(qaShape)),
  openSnackbar: func.isRequired
}

FAQ.defaultProps = {
  faqs: []
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      openSnackbar
    },
    dispatch
  )

export default Loader({ faqs: faqsQuery })(connect(null, mapDispatchToProps)(FAQ))
