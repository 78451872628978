import React from 'react'
import { object, bool, shape, arrayOf, func, string, number } from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Typography from 'components/shared/Typography'
import { unstable_Box as Box } from '@material-ui/core/Box'
import CircularProgress from '@material-ui/core/CircularProgress'
import LinearProgress from '@material-ui/core/LinearProgress'
import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'

import Page from 'components/Page/Page'
import NewsAndInsights from 'components/shared/NewsAndInsights'
import Input from 'components/Page/News/Input'
import Result from 'components/Page/News/Result'
import { growthCentreShape } from 'utils/shapes'

const styles = theme => ({
  tabsRoot: {
    boxShadow: 'none'
  },
  content: {
    flex: 1,
    backgroundColor: theme.palette.primary.light
  }
})

const News = ({
  classes,
  searching,
  loading,
  keyword,
  growthCentres,
  data,
  error,
  count,
  filter,
  onInputChange,
  onFilterChange
}) => (
  <Page title="News & Insights">
    <Grid container direction="column">
      <Box px={{ xs: 3, sm: 4, md: 8, lg: 19 }} pt={{ xs: 2, lg: 5 }}>
        <Grid container item direction="column">
          <Grid item>
            <Typography variant="h1" color="textPrimary">
              <Box mb={{ xs: 3, sm: 6 }}>Latest Barrier News and Case Studies</Box>
            </Typography>
          </Grid>
          <Grid item>
            <Box mb={{ xs: 3, sm: 6 }}>
              <Input onChange={onInputChange} value={keyword} disabled={searching || loading} />
            </Box>
          </Grid>
          <Grid item>
            <Tabs
              classes={{
                root: classes.tabsRoot
              }}
              value={filter}
              onChange={onFilterChange}
              indicatorColor="primary"
              textColor="primary"
              variant="scrollable"
              scrollButtons="on"
            >
              <Tab label="All" />
              {growthCentres.map(({ slug, name }) => (
                <Tab key={slug.current} label={name} />
              ))}
            </Tabs>
          </Grid>
        </Grid>
      </Box>
      <Grid container item direction="column" className={classes.content}>
        {searching ? (
          <Grid item>
            <Box mt={1}>
              <LinearProgress />
            </Box>
          </Grid>
        ) : null}
        <Box px={{ xs: 3, md: 8, lg: 19 }} py={2}>
          <Grid item>
            <Result
              searching={!!searching}
              loadingMore={loading}
              error={error}
              count={count}
              keyword={keyword}
              filter={!!filter}
              searchAll={() => onFilterChange(null, 0)}
            />
          </Grid>
          <Grid item>
            <Box py={1}>
              {data.map(({ articles, caseStudies }, index) => (
                <Box mt={2} key={index}>
                  <NewsAndInsights
                    loading={!!searching}
                    caseStudies={caseStudies}
                    articles={articles}
                    articleWithImagePosition={index % 3}
                    full
                  />
                </Box>
              ))}
            </Box>
          </Grid>
          {loading ? (
            <Grid container item justify="center">
              <Box my={1}>
                <CircularProgress />
              </Box>
            </Grid>
          ) : null}
        </Box>
      </Grid>
    </Grid>
  </Page>
)

News.propTypes = {
  classes: object.isRequired,
  loading: bool.isRequired,
  searching: bool.isRequired,
  keyword: string,
  error: bool.isRequired,
  filter: number.isRequired,
  count: number.isRequired,
  growthCentres: arrayOf(shape(growthCentreShape)),
  data: arrayOf(shape(growthCentreShape)),
  onInputChange: func.isRequired,
  onFilterChange: func.isRequired
}

News.defaultProps = {
  growthCentres: [],
  data: []
}

export default withStyles(styles)(News)
