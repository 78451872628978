import React from 'react'
import Grid from '@material-ui/core/Grid'
import { unstable_Box as Box } from '@material-ui/core/Box'
import Typography from 'components/shared/Typography'

import Affiliates from 'components/shared/Affiliates'
import Page from 'components/Page/Page'
import Card from 'components/Page/About/Card'

const steps = [
  {
    icon: 'speech-bubble.svg',
    name: 'Report your barrier',
    description: 'We capture the barrier you experienced and the impact it had on your business'
  },
  {
    icon: 'visible.svg',
    name: 'We give visibility to your barriers',
    description: 'We looks for trends in data to see who else is experiencing similar barriers.'
  },
  {
    icon: 'correct.svg',
    name: 'Action',
    description:
      'We look at ways to take action. This could be removing the barrier or guiding you how to reduce the impact of the barrier. '
  }
]

export const About = () => (
  <Page title="About">
    <Grid container direction="column">
      <Grid
        item
        style={{
          flex: 1,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundImage: 'url(/images/backgrounds/about.jpg)'
        }}
      >
        <Box px={{ xs: 2, sm: 4, md: 8, lg: 19 }} py={6}>
          <Grid
            container
            direction="column"
            style={{ backgroundColor: '#fff', boxShadow: '0 2px 20px 0 rgba(21,24,36,0.16)' }}
          >
            <Box p={{ xs: 2, sm: 4, lg: 6 }}>
              <Grid item>
                <Typography variant="h1" color="textPrimary">
                  <Box mb={{ xs: 2, md: 4 }}>About</Box>
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="h3" color="textPrimary">
                  <Box mb={5}>
                    The Industry Growth Centres, led by Food Innovation Australia Limited, have
                    partnered with the Export Council of Australia to give visibility to trade
                    barriers affecting Australian businesses. This allows business to be more
                    prepared and enables us to make an impact on trade negotiations and policies.
                  </Box>
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="h2" color="textPrimary">
                  <Box mb={{ xs: 2, md: 4 }}>Why is the TBR important</Box>
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body1">
                  The Australian Government has committed to pursuing regulatory reforms to cut red
                  tape and improve regulatory performance. The information you provide by
                  registering a trade barriers enables us to:
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body1">
                  <Box mb={5}>
                    <ul>
                      <li>
                        reveal reform pathways which better balance risk against regulatory burden;
                      </li>
                      <li>
                        develop industry-led options and recommendations to address specific reform
                        opportunities;
                      </li>
                      <li>
                        remove duplication of efforts and regulatory inconsistency between different
                        layers of government;
                      </li>
                      <li>
                        present evidence for reform, including quantifying burden on industry;
                      </li>
                      <li>consider the practicality of reform recommendations;</li>
                      <li>address both government and industry self-regulation; and</li>
                      <li>assist industry to streamline business to business regulation;</li>
                    </ul>
                  </Box>
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="h2" color="textPrimary">
                  <Box mb={5}>How it works</Box>
                </Typography>
              </Grid>
              <Grid container spacing={24}>
                {steps.map((step, index) => (
                  <Grid key={step.name} item xs={12} md={4}>
                    <Card
                      step={index + 1}
                      icon={step.icon}
                      name={step.name}
                      description={step.description}
                    />
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Grid>
        </Box>
      </Grid>
      <Grid item>
        <Box pl={{ xs: 2, sm: 4, md: 8, lg: 19 }} py={{ xs: 4, lg: 10 }}>
          <Affiliates />
        </Box>
      </Grid>
    </Grid>
  </Page>
)

export default About
