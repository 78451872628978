import React from 'react'
import { object, shape } from 'prop-types'
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'
import { unstable_Box as Box } from '@material-ui/core/Box'
import Typography from 'components/shared/Typography'

import Page from 'components/Page/Page'
import Loader from 'components/shared/Loader'
import PortableText from 'components/shared/PortableText'
import { contentPageQuery } from 'utils/sanity/queries'
import { htmlContentShape } from 'utils/shapes'

/* istanbul ignore next  */
const styles = ({ palette: { primary } }) => ({
  content: {
    backgroundColor: primary.light
  }
})

export const Privacy = ({ privacy, classes }) => (
  <Page title="Privacy Policy">
    <Grid container direction="column">
      <Box px={{ xs: 3, sm: 6, lg: 19 }}>
        <Grid item>
          <Typography variant="h1">
            <Box mb={2}>Privacy Policy</Box>
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="h3" color="textPrimary">
            <Box mb={{ xs: 6, sm: 8 }}>
              The Export Council of Australia takes your privacy seriously. This page sets out
              information about our Privacy Policy for the Trade Barriers Register and our partners.
            </Box>
          </Typography>
        </Grid>
      </Box>
      <Grid item className={classes.content}>
        <Box px={{ xs: 3, sm: 6, lg: 19 }} py={3}>
          {privacy ? <PortableText text={privacy.content} /> : <Box py={50} />}
        </Box>
      </Grid>
    </Grid>
  </Page>
)

Privacy.propTypes = {
  classes: object.isRequired,
  privacy: shape(htmlContentShape)
}

export default Loader({ privacy: contentPageQuery('privacy') })(withStyles(styles)(Privacy))
