import React from 'react'
import { shape } from 'prop-types'
import Grid from '@material-ui/core/Grid'
import { unstable_Box as Box } from '@material-ui/core/Box'
import Typography from 'components/shared/Typography'

import Organisations from 'components/shared/Organisations'
import isObjectNotNull from 'utils/isObjectNotNull'
import { agreementShape } from 'utils/shapes'

const Agreement = ({ agreement }) => {
  if (!isObjectNotNull(agreement)) return null

  return (
    <Grid container direction="column">
      <Grid
        item
        style={{
          height: '100%',
          boxShadow: '0 1px 3px 0 rgba(0,0,0,0.14)',
          backgroundColor: '#0E1D28'
        }}
      >
        <Box p={3}>
          <Box pb={10}>
            <Typography variant="h3" color="textSecondary">
              <Box mb={2}>Additional Resources</Box>
            </Typography>
            <Typography variant="body1" color="secondary">
              {agreement.name}
            </Typography>
          </Box>
          <Typography variant="body1" color="secondary" align="right">
            <a
              href={agreement.url}
              rel="noopener noreferrer"
              target="_blank"
              style={{ color: '#fff' }}
            >
              More Info <img alt="more info" src="/images/icons/more-white.svg" />
            </a>
          </Typography>
        </Box>
      </Grid>
      <Grid item>
        <Box mt={5} pl={3}>
          <Organisations organisations={agreement.organisations} />
        </Box>
      </Grid>
    </Grid>
  )
}

Agreement.propTypes = {
  agreement: shape(agreementShape)
}

export default Agreement
