import { handleActions } from 'redux-actions'

import { openSnackbar, closeSnackbar } from './actions'

export const INITIAL_STATE = {
  open: false,
  variant: 'success',
  message: ''
}

export default handleActions(
  {
    [openSnackbar]: (state, { payload: { variant, message } }) => ({
      open: true,
      variant,
      message
    }),
    [closeSnackbar]: state => ({
      ...state,
      open: false
    })
  },
  INITIAL_STATE
)
