import React from 'react'

import Page from 'components/Page/Page'

const surveyLink =
  'https://widget.surveymonkey.com/collect/website/js/tRaiETqnLgj758hTBazgd1WURjY9_2BH2xX9E25Ayfwc16s6sRwdc1b19KzIfVBkXg.js'
// old survey: https://widget.surveymonkey.com//collect/website/js/tRaiETqnLgj758hTBazgd92F53Hpjd_2F0GmT9iP6uDoBmhaapp5hawcsR2UOKkuuA.js

class Report extends React.Component {
  componentDidMount() {
    const s = document.createElement('script')
    s.type = 'text/javascript'
    s.async = true
    s.id = 'smcx-sdk'
    s.src = surveyLink
    this.instance.appendChild(s)
  }

  render() {
    return (
      <Page title="Report a Trade Barrier" hideFooter>
        <div ref={el => (this.instance = el)} style={{ width: '100%', height: '100%' }} />
      </Page>
    )
  }
}

export default Report
