import React from 'react'
import { object, shape, string } from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import { unstable_Box as Box } from '@material-ui/core/Box'
import Typography from 'components/shared/Typography'
import Hidden from '@material-ui/core/Hidden'

import { impactShape } from 'utils/shapes'

/* istanbul ignore next */
const styles = ({ breakpoints }) => ({
  impact: {
    position: 'relative',
    [`${breakpoints.down('sm')}`]: {
      bottom: '-90px'
    },
    [`${breakpoints.up('md')}`]: {
      top: '55px',
      left: '48px',
      right: '48px',
      bottom: '-90px'
    },
    [`${breakpoints.up('lg')}`]: {
      top: '145px',
      left: '0px',
      right: '0px',
      bottom: '0px'
    },
    [`${breakpoints.up('xl')}`]: {
      top: '140px',
      left: '0px',
      right: '0px',
      bottom: '0px'
    }
  }
})

const statisticsList = {
  barriersReported: {
    icon: 'speech-bubble.svg',
    label: 'Barriers Reported'
  },
  barriersActioned: {
    icon: 'correct.svg',
    label: 'Barriers Actioned'
  },
  insightsShared: {
    icon: 'presentation.svg',
    label: 'Insights Shared'
  }
}

export const Impact = ({ classes, statistics }) => (
  <Grid item className={classes.impact}>
    <Box py={{ xs: 4, sm: 5 }} px={{ xs: 4, sm: 12, md: 8, lg: 25 }}>
      <Grid container direction="column" style={{ backgroundColor: '#264CCA' }}>
        <Grid item style={{ padding: '18px' }}>
          <Typography variant="h2" align="center" color="textSecondary">
            <Box mb={0}>Our Impact</Box>
          </Typography>
        </Grid>
        <Grid
          container
          justify="space-between"
          style={{ backgroundColor: '#264CCA', padding: '18px', marginTop: '-10px' }}
        >
          {Object.keys(statisticsList).map(key => (
            <Grid key={key} container direction="column" item xs={3} spacing={24}>
              <Grid container direction="column" alignItems="center">
                <Grid container alignItems="center" justify="center">
                  <Grid item>
                    <Hidden mdDown>
                      <Box mr={2}>
                        <img alt={key} src={`/images/icons/${statisticsList[key].icon}`} />
                      </Box>
                    </Hidden>
                  </Grid>
                  <Grid item>
                    <Typography variant="h2" color="textSecondary">
                      {statistics[key] || 0}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Typography variant="h3" color="textSecondary" align="center">
                  {statisticsList[key].label}
                </Typography>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Box>
  </Grid>
)

Impact.propTypes = {
  classes: object.isRequired,
  background: string,
  statistics: shape(impactShape)
}

export default withStyles(styles)(Impact)
