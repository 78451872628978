import React from 'react'
import { shape, arrayOf, object } from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Typography from 'components/shared/Typography'
import { unstable_Box as Box } from '@material-ui/core/Box'

import Loader from 'components/shared/Loader'
import { organisationShape } from 'utils/shapes'
import { affiliateQuery } from 'utils/sanity/queries'

/* istanbul ignore next */
const styles = ({ breakpoints }) => ({
  logo: {
    [`${breakpoints.down('sm')}`]: {
      width: '90%'
    },
    [`${breakpoints.up('md')}`]: {
      width: '70%'
    }
  }
})

export const Affiliates = ({ classes, affiliates }) => (
  <Grid container direction="column">
    <Grid item xs={12}>
      <Typography variant="h2" color="textPrimary">
        We are a united voice representing Australian businesses
      </Typography>
    </Grid>
    <Box mt={{ xs: 3, md: 7 }}>
      <Grid container justify="space-around" alignItems="center">
        {affiliates.map(({ name, logo, url }) => (
          <Grid key={name} item xs={5} md={4} lg={3}>
            <a href={url} target="_blank" rel="noopener noreferrer" className="text-center">
              <Box py={{ xs: 1, sm: 2, md: 2.5 }}>
                <img alt={name} src={logo} className={classes.logo} />
              </Box>
            </a>
          </Grid>
        ))}
      </Grid>
    </Box>
  </Grid>
)

Affiliates.propTypes = {
  affiliates: arrayOf(shape(organisationShape)),
  classes: object.isRequired
}

Affiliates.defaultProps = {
  affiliates: []
}

export default Loader({ affiliates: affiliateQuery })(withStyles(styles)(Affiliates))
