import { createActions } from 'redux-actions'

export const {
  news: { updateNewsCache }
} = createActions({
  '@news/updateNewsCache': (keyword, growthCentre, news) => ({
    keyword,
    growthCentre,
    news
  })
})
