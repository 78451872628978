import React from 'react'
import { shape, arrayOf, bool } from 'prop-types'
import Grid from '@material-ui/core/Grid'
import { unstable_Box as Box } from '@material-ui/core/Box'

import ContentCell from 'components/shared/ContentCell'
import { caseStudyShape } from 'utils/shapes'

const CaseStudies = ({ loading, caseStudies }) => (
  <Grid container>
    {caseStudies.map(caseStudy => (
      <Grid item xs={12} lg={6} key={caseStudy._id}>
        <Box height="100%" pr={2} pb={2}>
          <ContentCell loading={loading} content={caseStudy} showImage />
        </Box>
      </Grid>
    ))}
  </Grid>
)

CaseStudies.propTypes = {
  loading: bool,
  caseStudies: arrayOf(shape(caseStudyShape))
}

export default CaseStudies
