import React from 'react'
import { string, number, object } from 'prop-types'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Hidden from '@material-ui/core/Hidden'
import { Link } from 'react-router-dom'
import { unstable_Box as Box } from '@material-ui/core/Box'

import MobileMenu from './MobileMenu'
import Menu from './Menu'

const paths = [
  {
    link: '/',
    name: 'Home'
  },
  // {
  //   link: '/about',
  //   name: 'About'
  // },
  {
    link: '/news',
    name: 'News and Case Studies'
  }
]

const button = {
  link: '/report',
  name: 'Register a Barrier'
}

const mobilePaths = [...paths, button]

/* istanbul ignore next */
const styles = ({ breakpoints }) => ({
  logo: {
    [`${breakpoints.only('xs')}`]: {
      width: '60px'
    },
    [`${breakpoints.only('sm')}`]: {
      width: '70px'
    },
    [`${breakpoints.up('md')}`]: {
      width: '100px'
    }
  }
})

const CustomAppBar = ({ classes, opacity, shrink, pathname }) => (
  <AppBar
    style={{
      position: 'fixed',
      top: 0,
      boxShadow: shrink < 0.5 ? 'none' : '0 2px 20px 0 rgba(21,24,36,0.16)',
      backgroundColor: `rgba(255, 255, 255, ${opacity})`
    }}
  >
    <Box py={5 - 3 * shrink}>
      <Toolbar>
        <Grid container justify="space-between" alignItems="center">
          <Grid item>
            <Link to="/">
              <Box ml={{ xs: 2, sm: 4, lg: 17 }}>
                <img
                  className={classes.logo}
                  alt="TBR Logo"
                  src={`/images/logos/tbr${opacity < 0.5 ? '-white' : '-blue'}.svg`}
                />
              </Box>
            </Link>
          </Grid>
          <Grid item>
            <Box mr={{ xs: 1, sm: 4, lg: 16 }}>
              <Hidden smUp>
                <MobileMenu opacity={opacity} paths={mobilePaths} />
              </Hidden>
              <Hidden only="xs">
                <Menu
                  currentPath={pathname}
                  paths={paths}
                  button={button}
                  opacity={opacity}
                  shrink={shrink}
                />
              </Hidden>
            </Box>
          </Grid>
        </Grid>
      </Toolbar>
    </Box>
  </AppBar>
)

CustomAppBar.propTypes = {
  classes: object.isRequired,
  opacity: number.isRequired,
  shrink: number.isRequired,
  pathname: string.isRequired
}

export default connect(({ router: { location: { pathname } } }) => ({
  pathname
}))(withStyles(styles)(CustomAppBar))
