import React from 'react'
import { shape, bool } from 'prop-types'
import Grid from '@material-ui/core/Grid'
import Typography from 'components/shared/Typography'
import { unstable_Box as Box } from '@material-ui/core/Box'
import { Link } from 'react-router-dom'
import Hidden from '@material-ui/core/Hidden'

import isObjectNotNull from 'utils/isObjectNotNull'
import PortableText from 'components/shared/PortableText'
import { qaShape } from 'utils/shapes'

const QA = ({ qa, heading, readMore, showLogo }) => {
  if (!isObjectNotNull(qa)) return null
  return (
    <Grid
      container
      direction="column"
      style={{
        backgroundColor: '#fff',
        boxShadow: '0 3px 3px 0 rgba(0,0,0,0.14)'
      }}
    >
      <Box px={{ xs: 2, sm: 3, lg: 9 }} py={{ xs: 3, sm: 5, lg: 5 }}>
        {heading ? (
          <Box mb={{ xs: 3, sm: 5, lg: 5 }}>
            <Grid container item justify="space-between">
              <Grid item>
                <Typography variant="h4" color="textPrimary">
                  Your question answered
                </Typography>
              </Grid>
            </Grid>
          </Box>
        ) : null}
        <Grid item>
          <Box pl={{ xs: 4, sm: 8, lg: 20 }}>
            <Box
              px={{ xs: 3, sm: 6, lg: 5, xl: 10 }}
              py={{ xs: 4, sm: 5, lg: 5, xl: 5 }}
              width="100%"
              height="100%"
              style={{
                backgroundRepeat: 'no-repeat',
                backgroundSize: '100% 100%',
                backgroundImage: 'url(/images/backgrounds/question.png)'
              }}
            >
              <Typography variant="body1" color="secondary">
                {qa.question}
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid container alignItems="center">
          <Grid item xs={12} lg={showLogo ? 9 : 12}>
            <Box
              px={{ xs: 4, sm: 5, lg: 7 }}
              py={{ xs: 9, sm: 5, lg: 7 }}
              width="100%"
              height="100%"
              style={{
                backgroundRepeat: 'no-repeat',
                backgroundSize: '100% 100%',
                backgroundImage: 'url(/images/backgrounds/answer.png)'
              }}
            >
              <PortableText text={qa.answer} />
            </Box>
          </Grid>
          <Hidden mdDown>
            {showLogo && qa.answeredBy ? (
              <Grid item lg={3}>
                <a href={qa.answeredBy.url} rel="noopener noreferrer" target="_blank">
                  <img
                    alt={qa.answeredBy.name}
                    src={qa.answeredBy.logo}
                    style={{ height: '60px', maxWidth: '100%' }}
                  />
                </a>
              </Grid>
            ) : null}
          </Hidden>
        </Grid>
        {readMore ? (
          <Grid item>
            <Link to="/faqs">
              <Typography variant="body1" color="primary" align="right">
                Read More <img alt="read more" src="/images/icons/more-black.svg" />
              </Typography>
            </Link>
          </Grid>
        ) : null}
      </Box>
    </Grid>
  )
}

QA.propTypes = {
  heading: bool,
  showLogo: bool,
  readMore: bool,
  qa: shape(qaShape)
}

export default QA
