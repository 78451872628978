import React from 'react'
import { shape, object } from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import { unstable_Box as Box } from '@material-ui/core/Box'
import Typography from 'components/shared/Typography'

import Header from './Header'
import CaseStudyOverview from 'components/shared/CaseStudy'
import PortableText from 'components/shared/PortableText'
import { caseStudyShape } from 'utils/shapes'

/* istanbul ignore next */
const styles = ({ breakpoints }) => ({
  background2: {
    width: '100%',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    backgroundSize: 'cover',
    [breakpoints.down('sm')]: {
      height: '300px'
    },
    [breakpoints.up('md')]: {
      height: '500px'
    },
    [breakpoints.up('lg')]: {
      height: '400px'
    },
    [breakpoints.up('xl')]: {
      height: '500px'
    }
  }
})

export const Main = ({ caseStudy, classes }) => (
  <Grid container direction="column">
    <Grid item>
      <Header
        heading={caseStudy.heading}
        background={caseStudy.background1}
        growthCentres={caseStudy.growthCentres.map(({ name }) => name)}
      />
    </Grid>
    <Grid item>
      <Box
        px={{ xs: 3, sm: 6, md: 8, lg: 19 }}
        pt={{ xs: 3, sm: 5, lg: 10 }}
        pb={{ xs: 0, sm: 5, lg: 15, xl: 20 }}
      >
        <CaseStudyOverview caseStudy={caseStudy} />
      </Box>
    </Grid>
    {caseStudy.background2 ? (
      <Grid
        container
        item
        className={classes.background2}
        style={{
          backgroundImage: `url(${caseStudy.background2})`
        }}
      />
    ) : null}
    <Grid container item direction="column">
      <Box px={{ xs: 3, sm: 6, md: 8, lg: 19 }}>
        <Grid item>
          <Typography variant="h1" color="textPrimary">
            <Box my={{ xs: 2, sm: 6 }}>{caseStudy.contentTitle}</Box>
          </Typography>
        </Grid>
        <Grid item>
          <Box my={{ xs: 2, sm: 6 }}>
            <PortableText text={caseStudy.content} />
          </Box>
        </Grid>
      </Box>
    </Grid>
  </Grid>
)

Main.propTypes = {
  classes: object.isRequired,
  caseStudy: shape(caseStudyShape).isRequired
}

export default withStyles(styles)(Main)
