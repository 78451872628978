import React from 'react'
import { object, string, bool } from 'prop-types'
import Grid from '@material-ui/core/Grid'
import { unstable_Box as Box } from '@material-ui/core/Box'
import Typography from 'components/shared/Typography'
import CircularProgress from '@material-ui/core/CircularProgress'

import Page from 'components/Page/Page'
import Main from 'components/Page/CaseStudy/Main'
import Loader from 'components/shared/Loader'
import OtherInterests from 'components/shared/OtherInterests'
import { caseStudyQuery } from 'utils/sanity/queries'

export class _CaseStudy extends React.Component {
  render() {
    const { loading, caseStudyId, ...props } = this.props
    const caseStudy = props[`caseStudy-${caseStudyId}`]

    return (
      <Page title="Case Study" transparentNav={!loading && caseStudy !== null}>
        <Grid container direction="column">
          {loading ? (
            <Grid container item justify="center">
              <Box py={10}>
                <CircularProgress />
              </Box>
            </Grid>
          ) : caseStudy ? (
            <Main caseStudy={caseStudy} />
          ) : (
            <Grid item>
              <Typography variant="h1" align="center">
                <Box py={{ xs: 5, sm: 10 }}>The case study has been removed or does not exist</Box>
              </Typography>
            </Grid>
          )}
          <Grid item style={{ backgroundColor: '#F2F2F2' }}>
            <Box px={{ xs: 3, sm: 6, md: 8, lg: 19 }} py={{ xs: 3, md: 6 }}>
              <OtherInterests darkBackground />
            </Box>
          </Grid>
        </Grid>
      </Page>
    )
  }
}

_CaseStudy.propTypes = {
  loading: bool,
  caseStudyId: string.isRequired
}

const CaseStudy = ({
  match: {
    params: { caseStudyId }
  }
}) => {
  const Component = Loader({
    [`caseStudy-${caseStudyId}`]: caseStudyQuery(caseStudyId)
  })(_CaseStudy)
  return <Component caseStudyId={caseStudyId} />
}

CaseStudy.propTypes = {
  match: object.isRequired
}

export default CaseStudy
