import React from 'react'
import { oneOfType, object, array, bool, string } from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'

import Footer from 'components/shared/Footer'
import Navigation from 'components/shared/Navigation'
import Title from 'components/shared/Navigation/Title'
import ScrollToTopOnMount from 'components/shared/ScrollToTopOnMount'
import Snackbar from 'components/shared/Snackbar'

/* istanbul ignore next */
const styles = ({ breakpoints }) => ({
  toolbar: {
    [breakpoints.down('xs')]: {
      minHeight: 30 + 110
    },
    [breakpoints.up('sm')]: {
      minHeight: 40 + 110
    },
    [breakpoints.up('lg')]: {
      minHeight: 50 + 110
    }
  },
  main: {
    width: '100%',
    flex: 1
  }
})

export const Page = ({ classes, children, transparentNav, title, hideFooter }) => (
  <Grid container direction="column">
    <Title title={title} />
    <ScrollToTopOnMount />
    <Navigation transparent={transparentNav} />
    {!transparentNav && <div className={classes.toolbar} />}
    <Grid item className={classes.main}>
      {children}
    </Grid>
    {!hideFooter ? (
      <Grid item>
        <Footer />
      </Grid>
    ) : null}
    <Snackbar />
  </Grid>
)

Page.propTypes = {
  hideFooter: bool,
  title: string,
  transparentNav: bool,
  classes: object.isRequired,
  children: oneOfType([object, array]).isRequired
}

export default withStyles(styles)(Page)
