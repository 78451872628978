import { createBrowserHistory } from 'history'
import { createStore, applyMiddleware, compose } from 'redux'
import { routerMiddleware } from 'connected-react-router'

import analyticsMiddleware from './analytics'
import createRootReducer from './reducers'

export const history = createBrowserHistory()

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
const middleware = [routerMiddleware(history), analyticsMiddleware]

const store = createStore(
  createRootReducer(history),
  composeEnhancers(applyMiddleware(...middleware))
)

export default store
