import blocksToHtml from '@sanity/block-content-to-html'
import { urlFor } from 'utils/sanity/makeImageUrl'

import { TBR_API_PROJECT_ID, TBR_API_DATASET } from 'utils/constants'

const h = blocksToHtml.h

const breakpoints = {
  xs: {
    width: '100%'
  },
  sm: {
    width: '100%'
  },
  md: {
    width: '80%'
  },
  lg: {
    width: '80%'
  },
  xl: {
    width: '80%'
  }
}

const serializers = width => ({
  types: {
    image: ({ node }) =>
      h('img', {
        src: urlFor(node.asset)
          .height(400)
          .url(),
        style: breakpoints[width]
      })
  }
})

export default width => blocks => ({
  __html: blocks
    ? blocksToHtml({
        blocks,
        projectId: TBR_API_PROJECT_ID,
        dataset: TBR_API_DATASET,
        serializers: serializers(width)
      })
    : ''
})
