import React from 'react'
import { shape, arrayOf, bool } from 'prop-types'
import Grid from '@material-ui/core/Grid'
import { unstable_Box as Box } from '@material-ui/core/Box'

import ContentCell from 'components/shared/ContentCell'
import { articleShape, caseStudyShape } from 'utils/shapes'

const Right = ({ caseStudy, articles, loading }) => (
  <Grid container>
    <Grid container item direction="column" xs={12} lg={4}>
      <Grid item style={{ flex: 1 }}>
        <Box pb={1} height="100%">
          <ContentCell loading={loading} content={articles[0]} />
        </Box>
      </Grid>
      <Grid item style={{ flex: 1 }}>
        <Box pt={1} height="100%">
          <ContentCell loading={loading} content={articles[1]} />
        </Box>
      </Grid>
    </Grid>
    <Grid item xs={12} lg={8}>
      <Box height="100%" pl={{ xs: 0, md: 2 }} pt={{ xs: 2, md: 0 }}>
        <ContentCell loading={loading} content={caseStudy} showImage />
      </Box>
    </Grid>
  </Grid>
)

Right.propTypes = {
  loading: bool,
  articles: arrayOf(shape(articleShape)),
  caseStudy: shape(caseStudyShape)
}

export default Right
