const truncateURL = (url = '') => {
  if (url.startsWith('https')) {
    url = url.slice(8)
  } else if (url.startsWith('http')) {
    url = url.slice(7)
  }

  if (url.startsWith('www')) {
    url = url.slice(4)
  }

  const firstSlash = url.indexOf('/')
  if (firstSlash !== -1) {
    url = url.slice(0, firstSlash)
  }
  return url
}

export default truncateURL
