import React from 'react'
import { number, arrayOf, shape, object } from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import MenuIcon from '@material-ui/icons/Menu'
import CloseIcon from '@material-ui/icons/Close'
import IconButton from '@material-ui/core/IconButton'
import Dialog from '@material-ui/core/Dialog'
import { Link } from 'react-router-dom'
import { unstable_Box as Box } from '@material-ui/core/Box'
import Typography from 'components/shared/Typography'
import Slide from '@material-ui/core/Slide'

import { pathShape } from 'utils/shapes'

function Transition(props) {
  return <Slide direction="left" {...props} />
}

const styles = theme => ({
  paper: {
    height: '100%',
    maxHeight: 'none',
    margin: '0 0 0 50px',
    backgroundColor: theme.palette.primary.contrastText
  }
})

export class MobileMenu extends React.Component {
  state = {
    open: false
  }

  handleClickOpen = () => {
    this.setState({
      open: true
    })
  }

  handleClose = () => {
    this.setState({
      open: false
    })
  }

  render() {
    const { paths, opacity, classes } = this.props
    const iconColor = opacity < 0.5 ? 'secondary' : 'primary'
    return (
      <div>
        <IconButton color={iconColor} aria-label="Menu" onClick={this.handleClickOpen}>
          <MenuIcon />
        </IconButton>
        <Dialog
          fullWidth
          open={this.state.open}
          onClose={this.handleClose}
          TransitionComponent={Transition}
          classes={{ paper: classes.paper }}
        >
          <Box pl={4}>
            <Grid container direction="column">
              <Grid container item justify="flex-end">
                <IconButton color="secondary" onClick={this.handleClose} aria-label="Close">
                  <CloseIcon />
                </IconButton>
              </Grid>
              {paths.map(({ link, name }) => (
                <Grid item key={link} onClick={this.handleClose}>
                  <Link to={link}>
                    <Typography variant="h3" color="textSecondary">
                      <Box my={1.5}>{name}</Box>
                    </Typography>
                  </Link>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Dialog>
      </div>
    )
  }
}

MobileMenu.propTypes = {
  classes: object.isRequired,
  paths: arrayOf(shape(pathShape)).isRequired,
  opacity: number.isRequired
}

export default withStyles(styles)(MobileMenu)
