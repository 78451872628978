import React from 'react'
import { shape, arrayOf, bool, number } from 'prop-types'
import Grid from '@material-ui/core/Grid'
import { unstable_Box as Box } from '@material-ui/core/Box'

import ArticleWithImage from './ArticleWithImage'
import DoubleArticles from './DoubleArticles'
import { articleShape, caseStudyShape } from 'utils/shapes'

const getArticle = (articleWithImagePosition, currentPosition, loading, articles) => {
  // if there is only one article, we display it with its image at the first column and display nothing at the others
  if (articles.length === 1 && currentPosition > 0) {
    return null
  }

  // Display the article with image at the current position or when there is only one article
  if (articleWithImagePosition === currentPosition || articles.length === 1)
    return <ArticleWithImage loading={loading} article={articles[0]} />

  // The article with image is not at position 0 so display DoubleArticles
  if (currentPosition === 0) {
    return <DoubleArticles loading={loading} articles={[articles[1], articles[2]]} />
  }

  if (currentPosition === 1) {
    if (articleWithImagePosition === 0)
      return <DoubleArticles loading={loading} articles={[articles[1], articles[2]]} />

    return <DoubleArticles loading={loading} articles={[articles[3], articles[4]]} />
  }

  if (currentPosition === 2) {
    return <DoubleArticles loading={loading} articles={[articles[3], articles[4]]} />
  }
}

const Articles = ({ loading, articles, articleWithImagePosition = 0 }) => (
  <Grid container>
    <Grid item xs={12} lg={4}>
      {getArticle(articleWithImagePosition, 0, loading, articles)}
    </Grid>
    <Grid item xs={12} lg={4}>
      <Box pl={{ xs: 0, lg: 2 }} pt={{ xs: 2, lg: 0 }} height="100%">
        {getArticle(articleWithImagePosition, 1, loading, articles)}
      </Box>
    </Grid>
    <Grid item xs={12} lg={4}>
      <Box pl={{ xs: 0, lg: 2 }} pt={{ xs: 2, lg: 0 }} height="100%">
        {getArticle(articleWithImagePosition, 2, loading, articles)}
      </Box>
    </Grid>
  </Grid>
)

Articles.propTypes = {
  loading: bool,
  articleWithImagePosition: number,
  articles: arrayOf(shape(articleShape)),
  caseStudy: shape(caseStudyShape)
}

export default Articles
