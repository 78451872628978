import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import { reducer as form } from 'redux-form'
import loader from 'components/shared/Loader/reducer'
import snackbar from 'components/shared/Snackbar/reducer'
import news from 'components/Page/News/reducer'

export default history =>
  combineReducers({
    router: connectRouter(history),
    form,
    loader,
    news,
    snackbar
  })
