import sanityClient from '@sanity/client'

import { TBR_API_USE_CDN, TBR_API_PROJECT_ID, TBR_API_DATASET } from '../constants'

const makeClient = (useCdn = TBR_API_USE_CDN) =>
  sanityClient({
    projectId: TBR_API_PROJECT_ID,
    dataset: TBR_API_DATASET,
    useCdn
  })

export default makeClient
