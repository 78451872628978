import React from 'react'
import { object } from 'prop-types'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import { Link } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import Typography from 'components/shared/Typography'
import { unstable_Box as Box } from '@material-ui/core/Box'

import Page from 'components/Page/Page'

/* istanbul ignore next */
const styles = ({ breakpoints }) => ({
  container: {
    flex: 1,
    padding: '250px 0 100px 0',
    [breakpoints.down('xs')]: {
      padding: '150px 0 50px 0'
    },
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundImage: 'url(/images/backgrounds/404.jpg)'
  },
  heading: {
    fontSize: 300,
    lineHeight: '250px',
    [breakpoints.down('xs')]: {
      fontSize: 100,
      lineHeight: '75px'
    }
  }
})

export const _404 = ({ classes }) => (
  <Page transparentNav title="Page Not Found">
    <Grid
      container
      direction="column"
      alignItems="center"
      className={classes.container}
      spacing={24}
    >
      <Grid item>
        <Typography variant="h1" className={classes.heading} color="secondary">
          404
        </Typography>
      </Grid>
      <Grid item style={{ marginBottom: '40px' }}>
        <Typography variant="h3" color="secondary">
          Sorry! Something went wrong
        </Typography>
      </Grid>
      <Grid item>
        <Button variant="contained" color="secondary" size="large" component={Link} to="/">
          <Typography variant="h3" color="primary">
            <Box p={2.5}>Return to Homepage</Box>
          </Typography>
        </Button>
      </Grid>
    </Grid>
  </Page>
)

_404.propTypes = {
  classes: object.isRequired
}

export default withStyles(styles)(_404)
