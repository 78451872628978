import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import Error from './Error'
import makeClient from 'utils/sanity/makeClient'
import queriesToRun from './queriesToRun'
import { updateData } from './actions'

const Loader = (queries = {}, useCache = true) => Component => {
  const queryKeys = Object.keys(queries)

  class InternalLoader extends React.Component {
    state = {
      loading: false,
      error: false,
      toRun: []
    }

    client = makeClient()

    // eslint-disable-next-line camelcase
    UNSAFE_componentWillMount() {
      const toRun = queriesToRun(queryKeys, this.props, useCache)

      if (toRun.length) {
        // some data is not available so we will load them
        this.setState({
          loading: true,
          toRun
        })
      }
    }

    async componentDidMount() {
      if (!this.state.loading) {
        // no need to load data
        return
      }

      try {
        const { toRun } = this.state
        const values = await Promise.all(toRun.map(query => this.client.fetch(queries[query])))

        const data = queryKeys.reduce((acc, key, index) => {
          acc[key] = values[index]
          return acc
        }, {})

        this.setState({ ...this.state, loading: false })
        this.props.updateData(data)
      } catch (error) {
        console.error(error)
        this.setState({ ...this.state, loading: false, error: true })
      }
    }

    render() {
      const { loading, error } = this.state
      if (!loading && error) {
        return <Error />
      }

      return <Component loading={this.state.loading} {...this.props} />
    }
  }

  const mapStateToProps = ({ loader }) =>
    queryKeys.reduce((acc, key) => {
      acc[key] = loader[key]
      return acc
    }, {})

  const mapDispatchToProps = dispatch =>
    bindActionCreators(
      {
        updateData
      },
      dispatch
    )

  return connect(mapStateToProps, mapDispatchToProps)(InternalLoader)
}

export default Loader
