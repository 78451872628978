import React from 'react'
import MuiTypography from '@material-ui/core/Typography'

const headlineMapping = {
  body1: 'span'
}

const Typography = props => <MuiTypography headlineMapping={headlineMapping} {...props} />

export default Typography
