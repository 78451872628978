import React from 'react'
import { shape, arrayOf, string } from 'prop-types'
import Grid from '@material-ui/core/Grid'
import { unstable_Box as Box } from '@material-ui/core/Box'

import Page from 'components/Page/Page'
import Header from 'components/Page/Home/Header'
import Loader from 'components/shared/Loader'
import Affiliates from 'components/shared/Affiliates'
import ThreeColumnSection from 'components/Page/Home/ThreeColumnSection'
import VideoSection from 'components/Page/Home/VideoSection'
import ImpactSection from 'components/Page/Home/Impact'
import TestimonialSection from 'components/Page/Home/TestimonialSection'
import { homeQuery } from 'utils/sanity/queries'

import { impactShape, articleShape, caseStudyShape, qaShape } from 'utils/shapes'

export const Home = ({ home: { content, highlightedCaseStudies, impact } }) => {
  return (
    <Page transparentNav>
      <Grid container direction="column">
        <Box mb={10} width="100%" height="100%">
          <Grid item>
            {impact && content && content.backgroundImageUrl && (
              <Header
                mission={content.mission}
                statistics={impact}
                background={content.backgroundImageUrl}
              />
            )}
          </Grid>
          <Grid item>
            <ThreeColumnSection pageContent={content && content.threeColumnSection} />
          </Grid>
          <Grid item style={{ backgroundColor: '#F0F4FF', paddingBottom: '25px' }}>
            <Box px={{ xs: 4, md: 8, lg: 19 }} mt={{ xs: 4, sm: 6, md: 10 }}>
              <VideoSection pageContent={content && content.videoSection} />
            </Box>
            <ImpactSection statistics={impact} />
          </Grid>
          <Grid
            item
            style={{
              backgroundColor: '#E2E8F7',
              textAlign: 'center',

              marginTop: '-50px',
              paddingBottom: '40px'
            }}
          >
            <Box px={{ xs: 4, md: 8, lg: 19 }} m={{ xs: 4, sm: 6, md: 10 }}>
              <TestimonialSection pageContent={content && content.testimonialSection} />
            </Box>
          </Grid>
          <Grid item>
            <Box px={{ xs: 4, md: 8, lg: 19 }} m={{ xs: 4, sm: 6, md: 10 }}>
              <Affiliates />
            </Box>
          </Grid>
        </Box>
      </Grid>
    </Page>
  )
}
Home.propTypes = {
  home: shape({
    background: shape({ url: string.isRequired }),
    impact: shape(impactShape),
    highlightedCaseStudies: arrayOf(shape(caseStudyShape)),
    articles: arrayOf(shape(articleShape)),
    caseStudy: shape(caseStudyShape),
    faq: shape(qaShape)
  })
}

Home.defaultProps = {
  home: {
    impact: {},
    highlightedCaseStudies: [],
    articles: []
  }
}

export default Loader({ home: homeQuery })(Home)
