import React from 'react'
import Grid from '@material-ui/core/Grid'
import { unstable_Box as Box } from '@material-ui/core/Box'
import Typography from 'components/shared/Typography'

const Header = () => (
  <Grid
    container
    direction="column"
    style={{
      position: 'relative',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center center',
      backgroundSize: 'cover',
      backgroundImage: 'url(/images/backgrounds/faq.jpg)'
    }}
  >
    <Box pl={{ xs: 2, sm: 4, md: 8, lg: 19 }} py={{ xs: 6, sm: 15, md: 20, lg: 25 }}>
      <Typography variant="h1" color="textSecondary">
        Frequently Asked Questions
      </Typography>
    </Box>
  </Grid>
)

export default Header
