import React from 'react'
import Slider from 'react-slick'
import { unstable_Box as Box } from '@material-ui/core/Box'
import Typography from 'components/shared/Typography'
import { array } from 'prop-types'

var settings = {
  dots: true,
  infinite: true,
  speed: 500,
  autoplay: true,
  autoplaySpeed: 3000,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false
}

const TestimonialSection = ({ pageContent }) => {
  if (!pageContent) return null
  const testimonials = pageContent

  return (
    <Slider {...settings}>
      {testimonials.map(t => (
        <Box key={t._key} px={{ xs: 2, sm: 4, md: 8, lg: 19 }} py={10}>
          <Box key={t._key} mb={3} mt={{ xs: 5, xl: 10 }}>
            <Typography variant="h3" color="textPrimary" style={{ fontSize: '30px' }}>
              {t.title}
            </Typography>
          </Box>
          <Typography
            variant="caption"
            color="textPrimary"
            paragraph
            style={{ fontStyle: 'italic', fontSize: '20px', fontWeight: '300' }}
          >
            &quot;{t.quote}&quot;
          </Typography>
          <Typography variant="caption" style={{ fontSize: '15px', fontWeight: '700' }}>
            {t.author}
          </Typography>
          <Typography variant="caption">{t.role}</Typography>
        </Box>
      ))}
    </Slider>
  )
}

TestimonialSection.propTypes = {
  pageContent: array
}

export default TestimonialSection
