import React from 'react'
import { object, string } from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import { unstable_Box as Box } from '@material-ui/core/Box'
import Typography from 'components/shared/Typography'
import Button from '@material-ui/core/Button'
import { Link } from 'react-router-dom'

/* istanbul ignore next */
const styles = ({ breakpoints }) => ({
  backImg: {
    position: 'relative',
    width: '100%',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    backgroundSize: 'cover',
    marginBottom: '20px',

    [`${breakpoints.down('sm')}`]: {},
    [`${breakpoints.up('sm')}`]: {},
    [`${breakpoints.up('lg')}`]: {
      height: '800px'
    },
    [`${breakpoints.up('xl')}`]: {
      height: '1000px'
    }
  }
})

export const Header = ({ mission, background, classes }) => (
  <Box
    px={{ xs: 4, sm: 6, lg: 19 }}
    pt={{ xs: 17, sm: 25, lg: 25, xl: 30 }}
    pb={{ xs: 22, sm: 30, lg: 30, xl: 35 }}
    mb={{ xs: 20, sm: 22, lg: 25 }}
    style={{
      backgroundImage: `url(${background})`
    }}
    className={classes.backImg}
  >
    <Grid container direction="column">
      <Grid item xs={12}>
        <Typography
          variant="h1"
          color="textSecondary"
          style={{
            fontWeight: 700,
            fontSize: '70px',
            lineHeight: '1em',
            width: '45%'
          }}
        >
          <Box mb={{ xs: 2, sm: 4, lg: 2 }}>Trade Barriers Register</Box>
        </Typography>
      </Grid>
      <Grid item xs={10} sm={5} lg={6} xl={4}>
        <Typography
          variant="h3"
          color="textSecondary"
          style={{ lineHeight: '23px', letterSpacing: '0.75px', fontWeight: 500 }}
        >
          <Box mb={{ xs: 3, md: 4, lg: 4 }}>{mission}</Box>
        </Typography>
      </Grid>
      <Grid item xs={10} lg={4}>
        <Button
          variant="contained"
          color="secondary"
          style={{ boxShadow: 'none' }}
          component={Link}
          to="/report"
        >
          <Typography variant="h3" color="textSecondary">
            <Box p={1}>Register a Trade Barrier</Box>
          </Typography>
        </Button>
        <Button
          color="secondary"
          style={{ boxShadow: 'none', margin: '15px' }}
          component={Link}
          to="/about"
        >
          <Typography variant="h3" color="textSecondary">
            <Box p={1}>Learn more</Box>
          </Typography>
        </Button>
      </Grid>
    </Grid>
  </Box>
)

Header.propTypes = {
  classes: object.isRequired,
  background: string,
  mission: string
}

export default withStyles(styles)(Header)
