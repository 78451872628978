import React from 'react'
import Grid from '@material-ui/core/Grid'
import Typography from 'components/shared/Typography'
import Button from '@material-ui/core/Button'
import { unstable_Box as Box } from '@material-ui/core/Box'
import { Link } from 'react-router-dom'
import { shape } from 'prop-types'
import { urlFor } from 'utils/sanity/makeImageUrl'

const ThreeColumnSection = ({ pageContent }) => {
  if (!pageContent) return null
  return (
    <Box px={{ xs: 2, sm: 4, md: 8, lg: 19 }} py={6}>
      <Grid container direction="column" align="center">
        <Grid item>
          <Typography
            style={{ fontWeight: 900, marginTop: '-150px' }}
            variant="h1"
            color="textPrimary"
            gutterBottom
          >
            {pageContent.sectionTitle}
          </Typography>
          <Typography variant="h3" style={{ color: '#6f6c6c', fontWeight: 500 }} gutterBottom>
            {pageContent.sectionDescription}
          </Typography>
          <Grid item xs={12}>
            <Button
              variant="contained"
              style={{ boxShadow: 'none', marginTop: '15px' }}
              component={Link}
              to="/report"
            >
              <Typography variant="h3" color="textSecondary">
                <Box p={1}>{pageContent.callToAction}</Box>
              </Typography>
            </Button>
          </Grid>
        </Grid>
        <Grid container justify="space-between" style={{ marginTop: '-60px' }}>
          {pageContent.icons.map(({ _key, icon, title, description }) => (
            <Grid key={_key} item sm={3} xs={12} style={{ padding: '15px', marginTop: '60px' }}>
              <Grid item>
                <Box mb={5} mt={{ xs: 5, sm: 5 }} style={{ minHeight: '160px' }}>
                  <img alt={_key} src={urlFor(icon.asset).url()} width="160" />
                </Box>
                <Typography
                  variant="h3"
                  color="textPrimary"
                  align="center"
                  style={{ fontWeight: 600, marginBottom: '10px' }}
                >
                  {title}
                </Typography>
                <Typography
                  variant="h4"
                  align="center"
                  style={{ fontWeight: 400, fontSize: '16px' }}
                >
                  {description}
                </Typography>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Box>
  )
}

ThreeColumnSection.propTypes = {
  pageContent: shape({})
}

export default ThreeColumnSection
