import React from 'react'
import { bool } from 'prop-types'
import AppBar from './AppBar'

class Navigation extends React.Component {
  state = {
    opacity: this.props.transparent ? 0 : 1,
    shrink: 0
  }

  // FIXME: When a page is unmounted, the new page will render the component ScrollToTopOnMount
  // to scroll to top. I don't understand why handleScroll of the unmounted page is still called
  // after componentWillUnmount. This is a temporary solution to prevent it.
  unmounted = false

  componentDidMount() {
    document.addEventListener('scroll', this.handleScroll)
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillUnmount() {
    this.unmounted = true
    window.removeEventListener('scroll', this.handleScroll)
  }

  handleScroll = () => {
    if (!this.unmounted) {
      this.setState({
        opacity: this.props.transparent ? Math.min(window.scrollY / 100, 1) : 1,
        shrink: Math.min(window.scrollY / 100, 1)
      })
    }
  }

  render() {
    const { opacity, shrink } = this.state

    return <AppBar opacity={opacity} shrink={shrink} />
  }
}

Navigation.propTypes = {
  transparent: bool
}

export default Navigation
