import React from 'react'
import { string, number, object } from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Avatar from '@material-ui/core/Avatar'
import Typography from 'components/shared/Typography'

const styles = {
  container: {
    position: 'relative',
    height: '100%',
    boxShadow: '0 2px 20px 0 rgba(21,24,36,0.16)',
    padding: '60px 20px'
  },
  icon: {
    paddingRight: '10px'
  },
  step: {
    position: 'absolute',
    top: '-10px',
    left: '-10px',
    color: '#fff',
    width: '34px',
    height: '34px',
    backgroundColor: '#1c3069'
  },
  spacing: {
    marginBottom: '20px'
  }
}

export const Card = ({ classes, step, icon, name, description }) => (
  <Grid container direction="column" className={classes.container}>
    <Avatar className={classes.step}>{step}</Avatar>
    <Grid container className={classes.spacing} style={{ alignItems: 'center' }}>
      <Grid item className={classes.icon}>
        <img alt={name} src={`/images/icons/${icon}`} />
      </Grid>
      <Grid item>
        <Typography variant="h4">{name}</Typography>
      </Grid>
    </Grid>
    <Grid item>
      <Typography variant="body1">{description}</Typography>
    </Grid>
  </Grid>
)

Card.propTypes = {
  classes: object.isRequired,
  step: number.isRequired,
  icon: string.isRequired,
  name: string.isRequired,
  description: string.isRequired
}

export default withStyles(styles)(Card)
