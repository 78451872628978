import React from 'react'
import { bool, func } from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import { unstable_Box as Box } from '@material-ui/core/Box'
import Typography from 'components/shared/Typography'

import Page from 'components/Page/Page'
import Input from 'components/shared/Form/Input'
import postContact from 'components/Page/Contact/postContact'
import { openSnackbar } from 'components/shared/Snackbar/actions'

export class Contact extends React.Component {
  doContact = data => {
    const { openSnackbar } = this.props
    return postContact(data).then(response => {
      if (!response.ok) {
        return openSnackbar(response.statusText, 'error')
      }
      this.props.reset()
      openSnackbar('Your message sent!')
      return response
    })
  }

  render() {
    const { handleSubmit, submitting } = this.props

    return (
      <Page title="Contact">
        <Box
          pt={{ xs: 5, sm: 10, md: 15, lg: 25 }}
          pb={{ xs: 10, sm: 15, md: 20, lg: 30 }}
          style={{
            flex: 1,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: 'center center',
            backgroundImage: 'url(/images/backgrounds/contact-us.jpg)'
          }}
          width="100%"
        >
          <Grid container direction="column" alignItems="center">
            <Grid container item xs={8} md={8} lg={4} xl={4} style={{ backgroundColor: '#fff' }}>
              <Box pt={5} pb={12} px={4}>
                <Grid item xs={12}>
                  <Typography variant="h1" color="textPrimary">
                    Contact
                  </Typography>
                </Grid>
                <form name="contact" onSubmit={handleSubmit(this.doContact)}>
                  <input type="hidden" name="form-name" value="contact" />
                  <Grid container spacing={24} justify="flex-end">
                    <Grid item xs={12}>
                      <Field
                        required
                        name="fullname"
                        type="text"
                        component={Input}
                        label="Full Name"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        required
                        name="email"
                        type="email"
                        component={Input}
                        label="Contact Email"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        required
                        name="message"
                        type="text"
                        component={Input}
                        label="Your Message"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Button
                        fullWidth
                        color="primary"
                        variant="contained"
                        size="large"
                        type="submit"
                        disabled={submitting}
                      >
                        <Typography variant="h4" color="textSecondary">
                          <Box py={1}>Send</Box>
                        </Typography>
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Page>
    )
  }
}

Contact.propTypes = {
  submitting: bool.isRequired,
  handleSubmit: func.isRequired,
  openSnackbar: func.isRequired,
  reset: func.isRequired
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      openSnackbar
    },
    dispatch
  )

export default reduxForm({
  form: 'contact'
})(connect(null, mapDispatchToProps)(Contact))
