/* eslint-disable no-multi-str */

const growthCentreProjection = ' \
  _id, \
  slug, \
  name \
'

const articleProjection = `
  _id,
  heading,
  source,
  url,
  'image': image.asset->url,
  summary,
  growthCentres[]->{
    ${growthCentreProjection}
  },
`

const organisationProjection = " \
  name, \
  url, \
  'logo': logo.asset->url \
"

const faqProjection = `
  _id,
  question,
  answer,
  answeredBy->{
    ${organisationProjection}
  },
  sortOrder
`

const agreementProjection = `
  name,
  url,
  organisations[]->{
    ${organisationProjection}
  }
`

const caseStudyProjection = `
  _id,
  heading,
  overview,
  growthCentres[]->{
    ${growthCentreProjection}
  },
  contentTitle,
  content,
  'background1': background1.asset->url,
  'background2': background2.asset->url,
  'agreement': *[_type == 'agreement' && _id in ^.agreements[]._ref] | order(_updatedAt desc)[0]{
    ${agreementProjection}
  },
  viewCount
`

const homePageProjection = `
  _id,
  mission,
  threeColumnSection,
  videoSection,
  testimonialSection,
  'backgroundImageUrl': background.asset->url
`

const excludeDraft = '&& !(_id in path("drafts.**"))'

export const homeQuery = `
  {
    'content': *[_type=='homePage' && documentName == 'home' ${excludeDraft}][0]{
      ${homePageProjection}
    },
    'impact': *[_type == 'impact' ${excludeDraft}]{barriersActioned, barriersReported, insightsShared}[0],
    'highlightedCaseStudies': *[_type == 'caseStudy' ${excludeDraft}] | order(_updatedAt desc){
      ${caseStudyProjection}
    }
  }
`

const growthCentreFilter = growthCentre =>
  growthCentre ? `&& growthCentres[]._ref == "${growthCentre._id}"` : ''

const splitKeywords = (keywords = '') =>
  keywords.split(' ').reduce((acc, keyword) => acc + `"*${keyword}*",`, '')

const keywordFilter = keywords =>
  `&& [heading, source, summary, overview, contentTitle, content] match [${splitKeywords(
    keywords
  )}]`

export const articleQuery = (keywords, growthCentre, slice) => {
  const mainQuery = `*[_type == 'article' && publish.status == "publish" ${growthCentreFilter(
    growthCentre
  )} ${keywordFilter(keywords)} ${excludeDraft}]`

  return `${mainQuery} | order(_updatedAt desc)[${slice}]{${articleProjection}}`
}

export const newsQuery = (keywords, growthCentre) => {
  const allCaseStudyQuery = `*[_type == 'caseStudy' ${growthCentreFilter(
    growthCentre
  )} ${keywordFilter(keywords)} ${excludeDraft}]`
  const caseStudyAndArticleQuery = `*[((_type == 'article' && publish.status == "publish") || _type == 'caseStudy') ${growthCentreFilter(
    growthCentre
  )} ${keywordFilter(keywords)} ${excludeDraft}]`

  return `
    {
      "allCaseStudies": ${allCaseStudyQuery} | order(_updatedAt desc){${caseStudyProjection}},
      "count": count(${caseStudyAndArticleQuery})
    }
  `
}

export const growthCentreQuery = `*[_type == 'growthCentre' ${excludeDraft}]{${growthCentreProjection}} | order( name asc)`

export const affiliateQuery = `*[_type == "organisation" ${excludeDraft}]{${organisationProjection}} | order( sortOrder asc)`

export const faqsQuery = `*[_type == 'faq' && enabled ${excludeDraft}]{'faqs': faqs[]->${faqProjection}}`

export const caseStudyQuery = id =>
  `*[_type == "caseStudy" && _id == "${id}" ${excludeDraft}]{${caseStudyProjection}} | order(_updatedAt desc)[0]`

export const otherInterestQuery = `
{
  'articles': *[_type == 'article' && publish.status == "publish" && defined(image) ${excludeDraft}][0..1]{
    ${articleProjection}
  },
  'faq': *[_type == 'faq' && enabled ${excludeDraft}][0]{
    ${faqProjection}
  }
}
`

export const contentPageQuery = page =>
  `*[_type=='page' && section == '${page}' ${excludeDraft}][0]{content}`
