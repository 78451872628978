import React from 'react'
import { shape, arrayOf, bool } from 'prop-types'

import Right from './Right'
import Left from './Left'
import { articleShape, caseStudyShape } from 'utils/shapes'

const ArticlesAndCaseStudy = ({ caseStudy, articles, caseStudyOnLeft, loading }) => {
  if (caseStudyOnLeft) {
    return <Left loading={loading} caseStudy={caseStudy} articles={articles} />
  }

  return <Right loading={loading} caseStudy={caseStudy} articles={articles} />
}

ArticlesAndCaseStudy.propTypes = {
  loading: bool,
  caseStudyOnLeft: bool,
  articles: arrayOf(shape(articleShape)),
  caseStudy: shape(caseStudyShape)
}

export default ArticlesAndCaseStudy
